<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <transition name="fade" mode="out-in">
    <div v-if="isVisible" class="bottom-menu" :style="{width}">
      <v-divider></v-divider>
      <v-container fluid class="px-4">
        <slot></slot>
      </v-container>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppBottomMenu',
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  watch: {
    isVisible(value) {
      if (value) this.setWidth();
    },
  },
  created() {
    window.addEventListener('resize', this.setWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.setWidth);
  },
  methods: {
    setWidth() {
      let mainWrap = document.querySelector('.v-main__wrap');
      mainWrap ? (this.width = `${mainWrap.offsetWidth}px`) : (this.width = '100%');
    },
  },
};
</script>

<style lang="scss">
.bottom-menu {
  position: fixed;
  z-index: get($index, default);
  bottom: 0;
  right: 0;
  width: 100%;

  .theme--light & {
    background-color: get($blue, 'lighten-7');
  }
  .theme--dark & {
    background-color: get($grey, 'darken-5');
  }
}
</style>
