export const gettersTypes = {
  testAnswers: '[settings] test answers',
  testTooltips: '[settings] test tooltips',
  isImpersonating: '[settings] is impersonating',
  isHideReports360: '[settings] hide reports 360',
  isHideSelfEval: '[settings] hide self eval',
  botLink: '[settings] bot link',
  // modules
  isEvaluationEnabled: '[settings] module evaluation is enabled',
  isFeedbackEnabled: '[settings] module feedback is enabled',
  isAdaptationEnabled: '[settings] module adaptation is enabled',
  isTestsAndSurveysEnabled: '[settings] module test and surveys is enabled',
  isPersonalTestsEnabled: '[settings] module personal tests is enabled',
  isInterconnectEnabled: '[settings] module interconnect is enabled',
  isTargetsEnabled: '[settings] module targets is enabled',
  isMeetingsEnabled: '[settings] module meetings is enabled',
  isAnyModuleEnabled: '[settings] any module is enabled',
  authUrl: '[company] get sso url',
  fourQuestionMode: '[settings] four question mode',
  adminAllowCompanyOrgs: '[settings] admin allow company orgs',
};

const getters = {
  [gettersTypes.testAnswers]: (state) => {
    if (!state.instance) return {};
    return state.instance.test_answer_texts;
  },
  [gettersTypes.testTooltips]: (state) => {
    if (!state.instance) return {};
    return state.instance.test_answer_tooltips;
  },
  [gettersTypes.isImpersonating]: (state) => {
    if (!state.instance) return false;
    return state.instance.impersonating;
  },
  [gettersTypes.isHideReports360]: (state) => {
    if (!state.instance) return false;
    return state.instance.hide_reports_360;
  },
  [gettersTypes.isHideSelfEval]: (state) => {
    if (!state.instance) return false;
    return state.instance.hide_self_eval;
  },
  [gettersTypes.botLink]: (state) => {
    if (!state.instance) return false;
    return Object.values(state.instance.BOT_LINKS)[0];
  },
  // modules
  [gettersTypes.isEvaluationEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.evaluation_enabled;
  },
  [gettersTypes.isFeedbackEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.feedback_enabled;
  },
  [gettersTypes.isAdaptationEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.adaptation_enabled;
  },
  [gettersTypes.isTestsAndSurveysEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.tests_and_surveys_enabled;
  },
  [gettersTypes.isPersonalTestsEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.ht_tests_enabled;
  },
  [gettersTypes.isInterconnectEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.interconnect_enabled;
  },
  [gettersTypes.isTargetsEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.targets_enabled;
  },
  [gettersTypes.isMeetingsEnabled]: (state) => {
    if (!state.instance) return false;
    return state.instance.meetings_enabled;
  },
  [gettersTypes.isAnyModuleEnabled]: (state, getters) => {
    return (
      getters[gettersTypes.isEvaluationEnabled] ||
      getters[gettersTypes.isFeedbackEnabled] ||
      getters[gettersTypes.isAdaptationEnabled] ||
      getters[gettersTypes.isTestsAndSurveysEnabled] ||
      getters[gettersTypes.isPersonalTestsEnabled] ||
      getters[gettersTypes.isInterconnectEnabled] ||
      getters[gettersTypes.isTargetsEnabled] ||
      getters[gettersTypes.isMeetingsEnabled]
    );
  },
  [gettersTypes.authUrl]: (state) => {
    return (state.instance || {}).SSO_DATA.auth_url;
  },
  [gettersTypes.fourQuestionMode]: (state) => {
    if (!state.instance) return false;
    return state.instance.four_question_mode;
  },
  [gettersTypes.adminAllowCompanyOrgs]: (state) => {
    if (!state.instance) return false;
    return state.instance.admin_allow_company_orgs;
  },
};

export default getters;
