import axios from 'axios';

const uploadFile = (data) => axios.post(`/utils/file`, data);

const getBoundTags = (id, relationTable, relationField, relationValues) =>
  axios.get(
    `/tag/company/${id}/relation/${relationTable}/field/${relationField}?relation_values=${relationValues}`
  );
const getTags = (id, search) => {
  const params = {
    company_id: id,
  };
  if (search !== undefined && search !== null && search !== '') {
    params.search = search;
  }
  return axios.get(`/tag`, {params});
};

const bindTags = (data) => axios.post('/tag/binding', data);
const bindTag = (tagId, data) => axios.post(`/tag/${tagId}`, data);
const createTag = (data) => axios.post(`/tag`, data);

export default {
  uploadFile,
  getBoundTags,
  getTags,
  bindTags,
  bindTag,
  createTag,
};
