<template>
  <v-breadcrumbs :items="items" class="grey--text" :class="classes">
    <template #divider>
      <v-icon v-text="'mdi-chevron-right'" />
    </template>
    <template #item="{item}">
      <v-breadcrumbs-item :to="item.to" exact-path>
        <span :class="{'blue--text': item.to}">{{ item.text }}</span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'AppBreadcrumbs',
  props: {
    items: {
      required: false,
      type: Array,
      default: () => [],
    },
    classes: {
      required: false,
      type: String,
      default: '',
    },
  },
};
</script>
