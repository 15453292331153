import {routeNames} from '@/router/names';
import {routeModules} from '@/router/modules';
import {routeAccessRights} from '@/router/accessRights';

const SurveysAnalytics = () =>
  import('@/components/pages/company/surveys/analytics/SurveysAnalyticsContainer');

const EmployeeWell = () =>
  import('@/components/pages/company/surveys/analytics/employee-well/EmployeeWellContainer');

const BurnoutAtWork = () =>
  import('@/components/pages/company/surveys/analytics/burnout-at-work/BurnoutAtWorkContainer');

const StatisticBlock = () =>
  import('@/components/pages/company/surveys/analytics/statistic/StatisticBlockContainer');

const companySurveysAnalyticsRoutes = [
  {
    path: 'researchment/:researchmentId/analytics',
    component: SurveysAnalytics,
    props: true,
    children: [
      {
        path: 'statistic',
        name: routeNames.main.company.companySurveysAnalyticsStatistic,
        component: StatisticBlock,
        meta: {
          modules: routeModules.main.company.companySurveysAnalyticsStatistic,
          accessRights: routeAccessRights.main.company.companySurveysAnalyticsStatistic,
        },
      },
      {
        path: 'wellbeing',
        name: routeNames.main.company.companySurveysAnalyticsWellbeing,
        component: EmployeeWell,
        props: true,
        meta: {
          modules: routeModules.main.company.companySurveysAnalyticsWellbeing,
          accessRights: routeAccessRights.main.company.companySurveysAnalyticsWellbeing,
        },
      },
      {
        path: 'burnout',
        name: routeNames.main.company.companySurveysAnalyticsBurnoutAtWork,
        component: BurnoutAtWork,
        meta: {
          modules: routeModules.main.company.companySurveysAnalyticsBurnoutAtWork,
          accessRights: routeAccessRights.main.company.companySurveysAnalyticsBurnoutAtWork,
        },
      },
    ],
  },
];

export default companySurveysAnalyticsRoutes;
