import axios from '@/api/axios';

const getCards = (userId) => axios.get(`/board/user/${userId}/cards`);

const getCard = (cardId, cardType) => axios.get(`/board/cards/${cardId}?card_type=${cardType}`);

const getRoadmaps = (userId) => axios.get(`/board/user/${userId}/roadmaps`);

const changeShowCards = (userId, data) => axios.post(`/board/user/${userId}/mass_hide_cards`, data);

export default {
  getCards,
  getCard,
  getRoadmaps,

  changeShowCards,
};
