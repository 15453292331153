<i18n>
{
  "ru": {
    "cropperTitle": "Редактировать изображение"
  },
  "en": {
    "cropperTitle": "Edit an image"
  }
}
</i18n>
<template>
  <div :id="id" class="app-avatar" :class="{'pe-3 mb-5': edit}">
    <component :is="tag" :size="size" :rounded="rounded" :class="classes" color="grey">
      <v-img v-if="loadingError || !avatarUrl">
        <v-icon dark :size="size / 2" v-text="`mdi-${icon}`"></v-icon>
      </v-img>
      <v-img v-else :src="avatarUrl" alt="user-avatar" @error="loadingError = true"></v-img>
    </component>
    <template v-if="edit">
      <input
        ref="fileInput"
        type="file"
        name="document"
        accept="image/*"
        class="d-none"
        @change="onFileSelected"
      />
      <v-btn
        small
        fab
        color="primary"
        class="primary-contrast--text"
        bottom
        right
        absolute
        @click="$refs.fileInput.click()"
      >
        <v-icon v-text="'mdi-pencil'"></v-icon>
      </v-btn>
      <!--
      <v-btn v-if="avatarUrl" small fab color="warning" top right absolute @click="onDelete">
        <v-icon v-text="'mdi-delete'"></v-icon>
      </v-btn>
      -->
      <v-dialog
        v-model="isDialogVisible.cropper"
        :max-width="600"
        :fullscreen="$vuetify.breakpoint.mobile"
        :scrollable="$vuetify.breakpoint.mobile"
      >
        <v-card class="position-relative">
          <v-btn
            absolute
            plain
            small
            icon
            top
            right
            :disabled="isSubmitting"
            @click="isVisibleState = false"
          >
            <v-icon v-text="'mdi-close'"></v-icon>
          </v-btn>
          <v-card-title>
            <app-title size="normal" weight="bold">{{ $t('cropperTitle') }}</app-title>
          </v-card-title>
          <v-card-text>
            <cropper
              ref="cropper"
              class="app-avatar__cropper"
              :class="{'is-loading': isSubmitting}"
              :src="cropperImg"
              :stencil-props="{aspectRatio: 1 / 1}"
              :stencil-component="$options.components.CircleStencil"
            ></cropper>
          </v-card-text>
          <v-card-actions>
            <v-container fluid pa-0>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    type="submit"
                    block
                    depressed
                    color="primary"
                    class="primary-contrast--text"
                    :disabled="isSubmitting"
                    @click="onSubmit"
                    >{{ $t('buttons.save') }}</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn outlined block @click="closeCropperDialog">{{
                    $t('buttons.cancel')
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {dialogsMixin} from '@/mixins/dialogs';
import {Cropper, CircleStencil} from 'vue-advanced-cropper';

export default {
  name: 'AppAvatar',
  components: {
    Cropper,
    CircleStencil,
  },
  mixins: [dialogsMixin],
  props: {
    tag: {
      type: String,
      required: false,
      default: 'v-avatar',
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: Number,
      required: false,
      default: null,
    },
    src: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: Number,
      required: true,
      default: 40,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'account',
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isDialogVisible: {
        cropper: false,
      },
      cropperImg: '',
      uploadFile: '',
      loadingError: false,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.user.isSubmitting,
    }),
    avatarUrl() {
      return this.src;
    },
  },
  methods: {
    onFileSelected({target}) {
      const reader = new FileReader();

      this.uploadFile = target.files[0];

      reader.readAsDataURL(this.uploadFile);
      reader.onload = () => {
        this.cropperImg = reader.result;
        this.toggleVisibleDialog({name: 'cropper', state: true});
      };
    },
    onDelete() {
      this.$emit('delete');
    },
    onSubmit() {
      const {canvas} = this.$refs.cropper.getResult();
      const base64File = canvas.toDataURL();

      this.$emit('change', base64File, this.id);
    },
    closeCropperDialog() {
      this.toggleVisibleDialog({name: 'cropper', state: false});
      this.resetForm();
    },
    resetForm() {
      this.$refs.fileInput.value = '';
    },
  },
};
</script>

<style scoped lang="scss">
.app-avatar {
  position: relative;

  .v-avatar.rounded {
    border-radius: $border-radius-root * 2 !important;
  }
  &__cropper {
    @include transition(opacity);
    width: 100%;
    height: $decimal-spacer * 30;
    background-color: get($grey, 'lighten-2');
    border-radius: $border-radius-root;
    overflow: hidden;

    &.is-loading {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
</style>
