<template>
  <div class="home">
    <v-container fluid>
      <app-loading></app-loading>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    this.$router.push({name: this.$routeNames.main.dashboard});
  },
};
</script>

<style lang="scss">
.home {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
