import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as components from 'vuetify/lib/components';
import {TiptapVuetifyPlugin} from 'tiptap-vuetify/dist/bundle-umd';

import variables from '@/helpers/variables';

const vuetify = new Vuetify(variables.vuetifySettings);

Vue.use(Vuetify, {components});
Vue.use(TiptapVuetifyPlugin, {vuetify});

export default vuetify;
