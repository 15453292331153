import axios from 'axios';

// Данные раздела статистики
const getStatistic = (
  companyId,
  researchmentId,
  reportType,
  params = {
    div1: undefined,
    div2: undefined,
    team: undefined,
    cycle: undefined,
    last_name: undefined,
    email: undefined,
  }
) =>
  axios.get(`/analytic/company/${companyId}/researchment/${researchmentId}/${reportType}`, {
    params,
  });

// Стадии выгорания сотрудников
// /analytic/company/{company_id}/researchment/{researchment_id}/burnout-by-employee/{report_type}
const getBurnoutByEmployee = (
  companyId,
  researchmentId,
  reportType,
  params = {
    div1: undefined,
    div2: undefined,
    team: undefined,
    cycle: undefined,
    last_name: undefined,
    email: undefined,
  }
) =>
  axios.get(
    `/analytic/company/${companyId}/researchment/${researchmentId}/burnout-by-employee/${reportType}`,
    {
      params,
    }
  );

// Выгорание сотрудников
// /analytic/company/{company_id}/researchment/{researchment_id}/burnout/{report_type}
const getBurnout = (
  companyId,
  researchmentId,
  reportType,
  params = {
    div1: undefined,
    div2: undefined,
    team: undefined,
    cycle: undefined,
    last_name: undefined,
    email: undefined,
  }
) =>
  axios.get(`/analytic/company/${companyId}/researchment/${researchmentId}/burnout/${reportType}`, {
    params,
  });

// Эмоциональное состояние сотрудников
// /analytic/company/{company_id}/researchment/{researchment_id}/emotion/{report_type}

const getEmotion = (
  companyId,
  researchmentId,
  reportType,
  params = {div1: undefined, div2: undefined, team: undefined, cycle: undefined}
) =>
  axios.get(`/analytic/company/${companyId}/researchment/${researchmentId}/emotion/${reportType}`, {
    params,
  });

// eNPS
// GET /analytic/company/{company_id}/researchment/{researchment_id}/npc
const getNPS = (
  companyId,
  researchmentId,
  params = {div1: undefined, div2: undefined, team: undefined, cycle: undefined}
) =>
  axios.get(`/analytic/company/${companyId}/researchment/${researchmentId}/enps`, {
    params,
  });

// Факторы вовлеченности и удовлетворённости
// GET /analytic/company/{company_id}/researchment/{researchment_id}/participation-factors/{report_type}
const getParticipationFactors = (
  companyId,
  researchmentId,
  reportType,
  params = {div1: undefined, div2: undefined, team: undefined, cycle: undefined}
) =>
  axios.get(
    `/analytic/company/${companyId}/researchment/${researchmentId}/participation-factors/${reportType}`,
    {
      params,
    }
  );

// Данные вовлеченность и удовлетворённость
// GET /analytic/company/{company_id}/researchment/{researchment_id}/participation/{report_type}
const getParticipation = (
  companyId,
  researchmentId,
  reportType,
  params = {div1: undefined, div2: undefined, team: undefined, cycle: undefined}
) =>
  axios.get(
    `/analytic/company/${companyId}/researchment/${researchmentId}/participation/${reportType}`,
    {
      params,
    }
  );

const getTeams = (companyId) => axios.get(`/analytic/company/${companyId}/teams`, {});

export default {
  getStatistic,
  getBurnoutByEmployee,
  getBurnout,
  getEmotion,
  getNPS,
  getParticipationFactors,
  getParticipation,
  getTeams,
};
