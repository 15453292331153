import settingsApi from '@/api/settings';
import {mutationTypes} from '@/store/modules/settings/mutations';

export const actionsTypes = {
  getSEOParams: '[settings] get SEO params',
  getInstanceSettings: '[settings] get instance',
  getWebsocketUrl: '[settings] get websocket url',
};

const actions = {
  [actionsTypes.getSEOParams](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSEOParamsStart);
      settingsApi
        .getSEOParams()
        .then((response) => {
          context.commit(mutationTypes.getSEOParamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSEOParamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getInstanceSettings](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getInstanceSettingsStart);
      settingsApi
        .getInstanceSettings()
        .then((response) => {
          context.commit(mutationTypes.getInstanceSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getInstanceSettingsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getWebsocketUrl](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getWebsocketUrlStart);
      settingsApi
        .getWebsocketUrl()
        .then((response) => {
          context.commit(mutationTypes.getWebsocketUrlSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getWebsocketUrlFailure);
          reject(result);
        });
    });
  },
};

export default actions;
