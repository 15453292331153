export const mutationTypes = {
  getAnnouncementsStart: '[announcements] get announcements start',
  getAnnouncementsSuccess: '[announcements] get announcements success',
  getAnnouncementsFailure: '[announcements] get announcements failure',

  getAnnouncementsAllStart: '[announcements] get announcements all start',
  getAnnouncementsAllSuccess: '[announcements] get announcements all success',
  getAnnouncementsAllFailure: '[announcements] get announcements all failure',

  createAnnouncementStart: '[announcements] create announcement start',
  createAnnouncementSuccess: '[announcements] create announcement success',
  createAnnouncementFailure: '[announcements] create announcement failure',

  deleteAnnouncementStart: '[announcements] delete announcement start',
  deleteAnnouncementSuccess: '[announcements] delete announcement success',
  deleteAnnouncementFailure: '[announcements] delete announcement failure',
};

const mutations = {
  [mutationTypes.getAnnouncementsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getAnnouncementsSuccess](state, payload) {
    state.isLoading = false;
    state.announcements = payload;
  },
  [mutationTypes.getAnnouncementsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAnnouncementsAllStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getAnnouncementsAllSuccess](state, payload) {
    state.isLoading = false;
    state.announcementsAll = payload;
  },
  [mutationTypes.getAnnouncementsAllFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createAnnouncementStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createAnnouncementSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createAnnouncementFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteAnnouncementStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteAnnouncementSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteAnnouncementFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
