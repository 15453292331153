import externalApi from '@/api/external';
import {mutationTypes} from '@/store/modules/external/mutations';

export const actionsTypes = {
  getStatuses: '[external] get task statuses',
};

const actions = {
  [actionsTypes.getStatuses](context, tasks) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getStatusesStart);
      externalApi
        .getStatuses(tasks)
        .then((response) => {
          context.commit(mutationTypes.getStatusesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getStatusesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
