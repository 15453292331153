import licenseApi from '@/api/license';
import {mutationTypes} from '@/store/modules/license/mutations';

export const actionsTypes = {
  getLicenses: '[license] get available licenses',
  assignLicenses: '[license] assign licenses',
};

const actions = {
  [actionsTypes.getLicenses](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getLicensesStart);
      licenseApi
        .getLicenses()
        .then((response) => {
          context.commit(mutationTypes.getLicensesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getLicensesFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.assignLicenses](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.assignLicensesStart);
      licenseApi
        .assignLicenses(data)
        .then((response) => {
          context.commit(mutationTypes.assignLicensesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.assignLicensesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
