<template>
  <v-row align="center">
    <v-col cols="auto">
      <div class="d-flex align-center">
        <slot name="title">
          <app-title v-bind="attrs">{{ title }}</app-title>
        </slot>

        <slot name="refresh">
          <template v-if="withRefreshButton">
            <v-btn
              v-if="$vuetify.breakpoint.mobile"
              :disabled="isLoading"
              icon
              small
              color="blue"
              class="ms-2"
              @click="$emit('title-refresh-click')"
            >
              <v-icon v-text="'mdi-refresh'"></v-icon>
            </v-btn>
            <v-btn
              v-else
              :disabled="isLoading"
              text
              color="blue"
              class="ms-2"
              @click="$emit('title-refresh-click')"
            >
              <v-icon left v-text="'mdi-refresh'"></v-icon>
              <span>{{ refreshButtonTitle }}</span>
            </v-btn>
          </template>
        </slot>
      </div>
    </v-col>

    <v-spacer />

    <slot name="search">
      <template v-if="withSearch">
        <v-col cols="auto">
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              class="w-100 w-sm-auto"
              dense
              outlined
              clearable
              hide-details
              :placeholder="`${$t('search')}...`"
              @keyup.enter="search ? doSearch() : () => {}"
              @click:clear="clearSearch"
            />
            <template v-if="search">
              <v-btn
                v-if="$vuetify.breakpoint.mobile"
                :disabled="isLoading"
                icon
                small
                color="blue"
                class="ms-2"
                @click="doSearch()"
              >
                <v-icon v-text="'mdi-magnify'"></v-icon>
              </v-btn>
              <v-btn
                v-else
                :disabled="isLoading"
                text
                color="blue"
                class="ms-2"
                @click="doSearch()"
              >
                <v-icon left v-text="'mdi-magnify'"></v-icon>
                <span>{{ searchButtonTitle }}</span>
              </v-btn>
            </template>
          </div>
        </v-col>
      </template>
    </slot>

    <slot name="action"></slot>

    <slot name="create">
      <v-col v-if="withCreateButton" cols="12" sm="auto">
        <v-btn
          :disabled="isLoading || createButtonDisabled"
          block
          depressed
          color="primary"
          class="primary-contrast--text"
          @click="$emit('title-create-click')"
        >
          {{ createButtonTitle }}
        </v-btn>
      </v-col>
    </slot>
  </v-row>
</template>

<script>
import i18n from '@/locale/i18n';
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppTitleWithButtons',

  props: {
    title: {
      type: String,
      required: true,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    withRefreshButton: {
      type: Boolean,
      default: true,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },

    refreshButtonTitle: {
      type: String,
      default: () => {
        return i18n.t('buttons.refresh');
      },
    },

    searchButtonTitle: {
      type: String,
      default: () => {
        return i18n.t('buttons.find');
      },
    },

    withCreateButton: {
      type: Boolean,
      default: false,
    },

    createButtonTitle: {
      type: String,
      default: () => {
        return i18n.t('buttons.create');
      },
    },

    createButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: undefined,
      hasSearch: false,
    };
  },

  computed: {
    attrs() {
      const result = this.$attrs;

      return Object.fromEntries(
        Object.entries(result).filter(([key]) => Object.keys(AppTitle.props).includes(key))
      );
    },
  },

  watch: {
    search(val) {
      const {hasSearch} = this;
      if (val === '' && hasSearch) {
        this.clearSearch();
      }
    },
  },

  methods: {
    doSearch() {
      const {search} = this;
      this.$emit('title-search-change', search);
      this.hasSearch = true;
    },

    clearSearch() {
      this.search = undefined;
      this.$emit('title-search-change', '');
      this.hasSearch = false;
    },
  },
};
</script>
