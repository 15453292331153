export const mutationTypes = {
  getTeamStart: '[team] get metadata start',
  getTeamSuccess: '[team] get metadata success',
  getTeamFailure: '[team] get metadata failure',

  getCompanyStart: '[team] get company start',
  getCompanySuccess: '[team] get company success',
  getCompanyFailure: '[team] get company failure',

  getSpecializationsStart: '[team] get specializations start',
  getSpecializationsSuccess: '[team] get specializations success',
  getSpecializationsFailure: '[team] get specializations failure',

  getMembersStart: '[team] get members start',
  getMembersSuccess: '[team] get members success',
  getMembersFailure: '[team] get members failure',

  addMemberStart: '[team] add member start',
  addMemberSuccess: '[team] add member success',
  addMemberFailure: '[team] add member failure',

  addMembersStart: '[team] add members start',
  addMembersSuccess: '[team] add members success',
  addMembersFailure: '[team] add members failure',

  deleteMemberStart: '[team] delete member start',
  deleteMemberSuccess: '[team] delete member success',
  deleteMemberFailure: '[team] delete member failure',

  changeLeaderAttrStart: '[team] change leader attribute start',
  changeLeaderAttrSuccess: '[team] change leader attribute success',
  changeLeaderAttrFailure: '[team] change leader attribute failure',

  getMetadataTestsStart: '[team] get metadata tests start',
  getMetadataTestsSuccess: '[team] get metadata tests success',
  getMetadataTestsFailure: '[team] get metadata tests failure',

  getMetadataTests360Start: '[team] get metadata tests 360 start',
  getMetadataTests360Success: '[team] get metadata tests 360 success',
  getMetadataTests360Failure: '[team] get metadata tests 360 failure',

  getStatisticsTest360Start: '[team] get statistics test 360 start',
  getStatisticsTest360Success: '[team] get statistics test 360 success',
  getStatisticsTest360Failure: '[team] get statistics test 360 failure',

  getDetailedMetadataTestsStart: '[team] get detailed metadata tests start',
  getDetailedMetadataTestsSuccess: '[team] get detailed metadata tests success',
  getDetailedMetadataTestsFailure: '[team] get detailed metadata tests failure',

  createTests360Start: '[team] create tests 360 start',
  createTests360Success: '[team] create tests 360 success',
  createTests360Failure: '[team] create tests 360 failure',

  getExpertsTest360Start: '[team] get experts for test 360 start',
  getExpertsTest360Success: '[team] get experts for test 360 success',
  getExpertsTest360Failure: '[team] get experts for test 360 failure',

  changeTest360Start: '[team] change test 360 start',
  changeTest360Success: '[team] change test 360 success',
  changeTest360Failure: '[team] change test 360 failure',

  changeStatusTest360Start: '[team] change status test 360 start',
  changeStatusTest360Success: '[team] change status test 360 success',
  changeStatusTest360Failure: '[team] change status test 360 failure',

  changePeriodTest360Start: '[team] change period test 360 start',
  changePeriodTest360Success: '[team] change period test 360 success',
  changePeriodTest360Failure: '[team] change period test 360 failure',

  reEvalLockOfTests360Start: '[team] re-evaluation lock of tests 360 start',
  reEvalLockOfTests360Success: '[team] re-evaluation lock of tests 360 success',
  reEvalLockOfTests360Failure: '[team] re-evaluation lock of tests 360 failure',

  setHiddenReports360Start: '[team] set hidden reports 360 start',
  setHiddenReports360Success: '[team] set hidden reports 360 success',
  setHiddenReports360Failure: '[team] set hidden reports 360 failure',

  exportTests360Start: '[team] export tests 360 start',
  exportTests360Success: '[team] export tests 360 success',
  exportTests360Failure: '[team] export tests 360 failure',

  exportRoadmaps360Start: '[team] export roadmaps 360 start',
  exportRoadmaps360Success: '[team] export roadmaps 360 success',
  exportRoadmaps360Failure: '[team] export roadmaps 360 failure',

  dryRunRemindersTests360Start: '[team] dry run reminders about tests 360 start',
  dryRunRemindersTests360Success: '[team] dry run reminders about tests 360 success',
  dryRunRemindersTests360Failure: '[team] dry run reminders about tests 360 failure',

  dryRunRemindersSelfTestsStart: '[team] dry run reminders about self tests start',
  dryRunRemindersSelfTestsSuccess: '[team] dry run reminders about self tests success',
  dryRunRemindersSelfTestsFailure: '[team] dry run reminders about self tests failure',

  dryRunRemindersPersonalTestsStart: '[team] dry run reminders about personal tests start',
  dryRunRemindersPersonalTestsSuccess: '[team] dry run reminders about personal tests success',
  dryRunRemindersPersonalTestsFailure: '[team] dry run reminders about personal tests failure',

  createRemindersTests360Start: '[team] create reminders about tests 360 start',
  createRemindersTests360Success: '[team] create reminders about tests 360 success',
  createRemindersTests360Failure: '[team] create reminders about tests 360 failure',

  createRemindersSelfTestsStart: '[team] create reminders about self tests start',
  createRemindersSelfTestsSuccess: '[team] create reminders about self tests success',
  createRemindersSelfTestsFailure: '[team] create reminders about self tests failure',

  createRemindersPersonalTestsStart: '[team] create reminders about personal tests start',
  createRemindersPersonalTestsSuccess: '[team] create reminders about personal tests success',
  createRemindersPersonalTestsFailure: '[team] create reminders about personal tests failure',
};

const mutations = {
  [mutationTypes.getTeamStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getTeamSuccess](state, payload) {
    state.isLoading = false;
    state.metadata = payload;
  },
  [mutationTypes.getTeamFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCompanyStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCompanySuccess](state, payload) {
    state.isLoading = false;
    state.company = payload;
  },
  [mutationTypes.getCompanyFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecializationsStart](state) {
    state.isLoading = true;
    state.specializations = null;
  },
  [mutationTypes.getSpecializationsSuccess](state, payload) {
    state.isLoading = false;
    state.specializations = payload;
  },
  [mutationTypes.getSpecializationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMembersStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMembersSuccess](state, payload) {
    state.isLoading = false;
    state.members = payload;
  },
  [mutationTypes.getMembersFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.addMemberStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.addMemberSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.addMemberFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.addMembersStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.addMembersSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.addMembersFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMemberStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteMemberSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMemberFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.changeLeaderAttrStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeLeaderAttrSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeLeaderAttrFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getMetadataTestsStart](state) {
    state.isLoading = true;
    state.metadataTests = null;
  },
  [mutationTypes.getMetadataTestsSuccess](state, payload) {
    state.isLoading = false;
    state.metadataTests = payload;
  },
  [mutationTypes.getMetadataTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMetadataTests360Start](state) {
    state.isLoading = true;
    state.metadataTests360 = null;
  },
  [mutationTypes.getMetadataTests360Success](state, payload) {
    state.isLoading = false;
    state.metadataTests360 = payload;
  },
  [mutationTypes.getMetadataTests360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getStatisticsTest360Start](state) {
    state.isLoading = true;
    state.statisticsTest360 = null;
  },
  [mutationTypes.getStatisticsTest360Success](state, payload) {
    state.isLoading = false;
    state.statisticsTest360 = payload;
  },
  [mutationTypes.getStatisticsTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getDetailedMetadataTestsStart](state) {
    state.isLoading = true;
    state.detailedMetadataTests = null;
  },
  [mutationTypes.getDetailedMetadataTestsSuccess](state, payload) {
    state.isLoading = false;
    state.detailedMetadataTests = payload;
  },
  [mutationTypes.getDetailedMetadataTestsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getExpertsTest360Start](state) {
    state.isLoading = true;
  },
  [mutationTypes.getExpertsTest360Success](state) {
    state.isLoading = false;
  },
  [mutationTypes.getExpertsTest360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeTest360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeTest360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeTest360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeStatusTest360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeStatusTest360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeStatusTest360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changePeriodTest360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changePeriodTest360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changePeriodTest360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.reEvalLockOfTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.reEvalLockOfTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.reEvalLockOfTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.setHiddenReports360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.setHiddenReports360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.setHiddenReports360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportRoadmaps360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportRoadmaps360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportRoadmaps360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersSelfTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersSelfTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersSelfTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.dryRunRemindersPersonalTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.dryRunRemindersPersonalTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.dryRunRemindersPersonalTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersTests360Start](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersTests360Success](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersTests360Failure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersSelfTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersSelfTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersSelfTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createRemindersPersonalTestsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRemindersPersonalTestsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRemindersPersonalTestsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
