import axios from '@/api/axios';

// skills
const getUserList = (
  search = undefined,
  company_ids = undefined,
  limit = undefined,
  offset = undefined
) => {
  let args = {};
  if (search) args.search = search;
  if (limit) args.limit = limit;
  if (offset) args.offset = offset;
  if (company_ids && company_ids.length) args.company_ids = company_ids.join(',');
  return axios.get(`/administration/user`, {params: args});
};

const toggleUserBanState = (uid, banned) =>
  axios.post(`/administration/user/${uid}/toggle-ban/${banned ? 'ban' : 'unban'}`);

const toggleUserImp = (uid, action) =>
  axios.post(`/administration/user/${uid}/impersonate/${action}`);

const changeUserEmail = (uid, new_email) =>
  axios.post(`/administration/user/${uid}/change-email/${new_email}`);

const getCompanyList = () => axios.get(`/administration/company`);

export default {
  getUserList,
  toggleUserBanState,
  toggleUserImp,
  getCompanyList,
  changeUserEmail,
};
