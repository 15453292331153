<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <component
    :is="tag"
    class="app-title"
    :class="[size && `app-title-size--${size}`, weight && `app-title-weight--${weight}`, classes]"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'AppTitle',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'h2',
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    weight: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.app-title {
  font-family: $heading-font-family;
  word-break: break-word;
  line-height: 1.2;
  letter-spacing: normal;

  .custom-font & {
    font-family: inherit;
    letter-spacing: 0.03em;
  }
  &-weight {
    &--semibold {
      font-weight: 600;
    }
    &--bold {
      font-weight: 700;
    }
  }
  &-size {
    &--default {
      @include font-size(get($title-sizes, 'default'));
    }
    &--extra-small {
      @include font-size(get($title-sizes, 'extra-small'));
    }
    &--small {
      @include font-size(get($title-sizes, 'small'));
    }
    &--normal {
      @include font-size(get($title-sizes, 'normal'));
    }
    &--middle {
      @include font-size(get($title-sizes, 'middle'));
    }
    &--large {
      @include font-size(get($title-sizes, 'large'));
    }
    &--extra-large {
      @include font-size(get($title-sizes, 'extra-large'));
    }
    &--super-large {
      @include font-size(get($title-sizes, 'super-large'));
      line-height: 1;
    }
    &--huge {
      @include font-size(get($title-sizes, 'huge'));
      line-height: 1;
    }
  }
}
</style>
