import ru from 'vuetify/es5/locale/ru';
import defaultTheme from '@/themes/default';

const variables = {
  vuetifySettings: {
    theme: {
      dark: false,
      themes: defaultTheme,
      options: {
        customProperties: true,
      },
    },
    icons: {
      iconfont: 'mdi',
    },
    lang: {
      locales: {ru},
      current: 'ru',
    },
  },
  isOpenSidebar: false,
  isVisibleSidebar: true,
  isTesting: false,
  isTestingReady: false,
  alert: {
    visible: false,
    messages: [],
    event: {
      emailNotChanged: 'E-mail не изменён',
      emailChanged: 'E-mail изменён',
    },
  },
  sezamePassword: 2729429,
  isSezameOpened: false,
  isJivoDateSent: false,
  faqUrl: 'https://faq.beehive.team/',
  hostUrl:
    process.env.NODE_ENV !== 'production'
      ? 'https://beehive-vue.test.bobdaytech.ru/api/v1'
      : '/api/v1',
  libs: {
    chart: 'https://cdn.jsdelivr.net/npm/chart.js@2.9.3/dist/Chart.min.js',
    jquery: 'https://code.jquery.com/jquery-3.2.1.min.js',
  },
  icons: {
    skype:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path d="M610.5,492.4c-11.7-7.9-26.2-14.6-42.9-20.2c-16.5-5.5-35.1-10.5-55.5-15c-16-3.8-27.7-6.8-34.7-8.8c-6.8-1.9-13.6-4.6-20.2-8.1c-6.3-3.3-11.4-7.2-15-11.7c-3.3-4.2-5-9.2-5-15.1c0-9.6,5.1-17.7,15.6-24.7c10.8-7.3,25.5-11,43.4-11c19.4,0,33.5,3.3,41.9,9.9c8.7,6.8,16.2,16.4,22.5,28.7c5.4,9.6,10.3,16.2,15,20.4c5.1,4.6,12.3,7,21.6,7c10.3,0,18.9-3.7,25.8-11.1c6.8-7.3,10.3-15.7,10.3-24.9c0-9.6-2.7-19.5-7.9-29.4s-13.4-19.3-24.3-28.2s-24.9-15.9-41.4-21.2c-16.4-5.3-36.2-8-58.5-8c-28.1,0-52.8,4-73.7,11.9c-21.1,8.1-37.5,19.8-48.7,34.9c-11.3,15.2-17,32.7-17,52.2c0,20.4,5.5,37.8,16.2,51.7c10.6,13.7,25.1,24.6,43.1,32.5c17.7,7.7,39.7,14.4,65.8,20.2c19.1,4.1,34.6,8.1,46,11.7c10.9,3.5,20,8.6,26.8,15.2c6.5,6.3,9.6,14.2,9.6,24.6c0,12.9-6.1,23.3-18.5,32.2c-12.8,9-29.8,13.5-50.5,13.5c-15.1,0-27.3-2.2-36.4-6.7c-9-4.4-16.1-10-21-16.6c-5.2-7-10-15.9-14.4-26.3c-4-9.6-9-17-14.8-22.2c-6-5.3-13.5-7.9-22-7.9c-10.6,0-19.3,3.4-26.2,10c-6.9,6.8-10.5,15-10.5,24.4c0,15.1,5.4,30.8,16.1,46.5c10.6,15.8,24.4,28.4,41.3,37.8c23.6,12.9,53.8,19.3,89.8,19.3c30,0,56.3-4.8,78.4-14.1c22.2-9.5,39.4-22.9,50.9-39.8c11.6-16.9,17.6-36.4,17.6-57.6c0-17.8-3.4-33.1-10.2-45.5C632,510.8,622.5,500.5,610.5,492.4L610.5,492.4z M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M617.4,774.3c-24.9,0-48.3-6.4-68.8-17.8c-14.9,2.9-30.2,4.4-45.9,4.4c-138.5,0-250.8-115.7-250.8-258.1c0-17.9,1.8-35.2,5.1-52c-12.7-22.2-20-48-20-75.6c0-82.6,65.1-149.8,145.5-149.8c28.4,0,54.9,8.5,77.4,23c13.9-2.5,28.3-3.8,42.8-3.8c138.6,0,250.9,115.6,250.9,258.1c0,18.9-2,37.5-5.8,55.3c9.6,20.1,15.1,42.6,15.1,66.5C762.9,707.3,697.8,774.3,617.4,774.3L617.4,774.3z"/></svg>',
    behance:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2500"><style>.st1{fill:#fff}</style><circle cx="1250" cy="1250" r="1250"/><path class="st1" d="M883.1 683.6c53.9 0 103.1 4.8 147.6 14.3s82.5 25.1 114.2 46.8c31.7 21.7 56.3 50.5 73.8 86.5s26.2 80.4 26.2 133.3c0 57.1-13 104.7-38.9 142.8-25.9 38.1-64.3 69.3-115 93.6 69.8 20.1 121.9 55.3 156.3 105.5 34.4 50.2 51.6 110.8 51.6 181.7 0 57.1-11.1 106.6-33.3 148.4s-52.1 75.9-89.7 102.3c-37.6 26.5-80.4 46-128.5 58.7-48.4 12.7-98.3 19.1-148.3 19H350V683.6h533.1zm-31.7 458.5c44.4 0 80.9-10.6 109.5-31.7 28.6-21.2 42.9-55.5 42.8-103.1 0-26.4-4.8-48.1-14.3-65s-22.2-30.2-38.1-39.7c-15.9-9.5-34.1-16.1-54.7-19.8-21.2-3.7-42.7-5.6-64.3-5.5H599.2v265l252.2-.2zm14.3 480.7c23.5.1 46.9-2.3 69.8-7.1 22.2-4.8 41.8-12.7 58.7-23.8 16.9-11.1 30.4-26.2 40.5-45.2 10-19 15.1-43.4 15.1-73 0-58.1-16.4-99.7-49.2-124.5-32.8-24.9-76.2-37.3-130.1-37.3H599.2v311l266.5-.1zm746.6-23.8c33.8 32.8 82.5 49.2 146 49.2 45.5 0 84.6-11.4 117.4-34.1 32.8-22.7 52.9-46.8 60.3-72.2h198.3c-31.7 98.4-80.4 168.7-146 211-65.6 42.3-144.9 63.5-238 63.5-64.5 0-122.7-10.3-174.5-30.9-51.8-20.6-95.7-50-131.7-88.1-36-38.1-63.8-83.5-83.3-136.4-19.5-52.9-29.3-111.1-29.3-174.5 0-61.3 10-118.4 30.1-171.3 20.1-52.9 48.7-98.6 85.7-137.2 37-38.6 81.2-69 132.5-91.2 51.3-22.2 108.2-33.3 170.6-33.3 69.8 0 130.6 13.5 182.5 40.5 51.8 27 94.4 63.2 127.7 108.7s57.4 97.3 72.2 155.5c14.8 58.2 20.1 119 15.9 182.5h-591.8c3.1 72.7 21.6 125.6 55.4 158.3zm254.7-431.5c-27-29.6-68-44.4-123-44.4-36 0-65.9 6.1-89.6 18.2-23.8 12.2-42.8 27.2-57.1 45.2-14.3 18-24.3 37-30.1 57.1-5.8 20.1-9.3 38.1-10.3 53.9h366.5c-10.7-57-29.5-100.4-56.4-130zm-348.7-426.4h457.9v127h-457.9v-127z"/></svg>',
    telegram:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" r="120"></circle><path d="M81.229,128.772l14.237,39.406s1.78,3.687,3.686,3.687,30.255-29.492,30.255-29.492l31.525-60.89L81.737,118.6Z" fill="#c8daea"/><path d="M100.106,138.878l-2.733,29.046s-1.144,8.9,7.754,0,17.415-15.763,17.415-15.763" fill="#a9c6d8"/><path d="M81.486,130.178,52.2,120.636s-3.5-1.42-2.373-4.64c.232-.664.7-1.229,2.1-2.2,6.489-4.523,120.106-45.36,120.106-45.36s3.208-1.081,5.1-.362a2.766,2.766,0,0,1,1.885,2.055,9.357,9.357,0,0,1,.254,2.585c-.009.752-.1,1.449-.169,2.542-.692,11.165-21.4,94.493-21.4,94.493s-1.239,4.876-5.678,5.043A8.13,8.13,0,0,1,146.1,172.5c-8.711-7.493-38.819-27.727-45.472-32.177a1.27,1.27,0,0,1-.546-.9c-.093-.469.417-1.05.417-1.05s52.426-46.6,53.821-51.492c.108-.379-.3-.566-.848-.4-3.482,1.281-63.844,39.4-70.506,43.607A3.21,3.21,0,0,1,81.486,130.178Z" fill="#fff"/></svg>',
    vk: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.75 97.75"><path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z    M73.667,54.161c2.278,2.225,4.688,4.319,6.733,6.774c0.906,1.086,1.76,2.209,2.41,3.472c0.928,1.801,0.09,3.776-1.522,3.883   l-10.013-0.002c-2.586,0.214-4.644-0.829-6.379-2.597c-1.385-1.409-2.67-2.914-4.004-4.371c-0.545-0.598-1.119-1.161-1.803-1.604   c-1.365-0.888-2.551-0.616-3.333,0.81c-0.797,1.451-0.979,3.059-1.055,4.674c-0.109,2.361-0.821,2.978-3.19,3.089   c-5.062,0.237-9.865-0.531-14.329-3.083c-3.938-2.251-6.986-5.428-9.642-9.025c-5.172-7.012-9.133-14.708-12.692-22.625   c-0.801-1.783-0.215-2.737,1.752-2.774c3.268-0.063,6.536-0.055,9.804-0.003c1.33,0.021,2.21,0.782,2.721,2.037   c1.766,4.345,3.931,8.479,6.644,12.313c0.723,1.021,1.461,2.039,2.512,2.76c1.16,0.796,2.044,0.533,2.591-0.762   c0.35-0.823,0.501-1.703,0.577-2.585c0.26-3.021,0.291-6.041-0.159-9.05c-0.28-1.883-1.339-3.099-3.216-3.455   c-0.956-0.181-0.816-0.535-0.351-1.081c0.807-0.944,1.563-1.528,3.074-1.528l11.313-0.002c1.783,0.35,2.183,1.15,2.425,2.946   l0.01,12.572c-0.021,0.695,0.349,2.755,1.597,3.21c1,0.33,1.66-0.472,2.258-1.105c2.713-2.879,4.646-6.277,6.377-9.794   c0.764-1.551,1.423-3.156,2.063-4.764c0.476-1.189,1.216-1.774,2.558-1.754l10.894,0.013c0.321,0,0.647,0.003,0.965,0.058   c1.836,0.314,2.339,1.104,1.771,2.895c-0.894,2.814-2.631,5.158-4.329,7.508c-1.82,2.516-3.761,4.944-5.563,7.471   C71.48,50.992,71.611,52.155,73.667,54.161z"/></svg>',
  },
};

export default variables;
