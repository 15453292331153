export const mutationTypes = {
  getLicensesStart: '[license] get available licenses start',
  getLicensesSuccess: '[license] get available licenses success',
  getLicensesFailure: '[license] get available licenses failure',

  assignLicensesStart: '[license] assign licenses start',
  assignLicensesSuccess: '[license] assign licenses success',
  assignLicensesFailure: '[license] assign licenses failure',
};

const mutations = {
  [mutationTypes.getLicensesStart](state) {
    state.licenses = null;
    state.isLoading = true;
  },
  [mutationTypes.getLicensesSuccess](state, payload) {
    state.isLoading = false;
    state.licenses = payload;
  },
  [mutationTypes.getLicensesFailure](state) {
    state.isLoading = false;
  },
  [mutationTypes.assignLicensesStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.assignLicensesSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.assignLicensesFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
