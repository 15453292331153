import is from 'is_js';

import targets from '@/helpers/seo/targets';
import YandexModule from '@/helpers/seo/modules/yandex';
import FacebookModule from '@/helpers/seo/modules/facebook';

class SEOModule {
  constructor() {
    this.initializedMetrics = [];
    this.disabled = false;
    this.debug = false;
    this.visitParams = {};
    this.metricCounters = {};
    this.ignoredEmails = [];
    this.targets = targets;
  }

  createMetric(metric) {
    const metricModuleMap = {
      yandex: () => new YandexModule(),
      yandex_aux: () => new YandexModule(),
      facebook: () => new FacebookModule(),
    };
    if (!metricModuleMap || !Object.hasOwn(metricModuleMap, metric)) {
      console.warn('SEO init failed');
      return false;
    }
    this[metric] = metricModuleMap[metric]();
  }

  standartLog() {
    if (!this.debug) {
      return;
    }
    if (this['msg']) {
      console.log(this['msg']);
    }
  }

  registerHit(url, options) {
    if (this.disabled) {
      return;
    }
    const me = this;
    me.initializedMetrics.map((metric) => me[metric].registerHit(url, options));
  }

  registerTarget(eventName) {
    if (this.disabled) {
      return;
    }
    const me = this;
    me.initializedMetrics.map((metric) => {
      let targetId = me['targets'][eventName][metric];
      if (targetId) {
        me[metric].registerTarget(targetId);
      }
    });
  }

  registerExternalLink(link) {
    if (this.disabled) {
      return;
    }
    const me = this;
    me.initializedMetrics.map((metric) => me[metric].registerExternalLink(link));
  }

  assignUser(userId, payload) {
    if (this.disabled) {
      return;
    }
    const me = this;
    if (me.ignoredEmails.includes(payload.get('email'))) {
      me.initializedMetrics = [];
      me.disabled = true;
      console.log('SEO module is ignoring current user and is now disabled');
    }
    me.initializedMetrics.map((metric) => me[metric].assignUser(userId, payload));
  }

  initMonitoring(settings) {
    if (is.empty(settings)) {
      return console.log('SEO params are empty');
    }
    const me = this;
    const shouldEnableMonitoring = settings['enabled'] || false;
    me.debug = settings['debug'] || false;
    me.disabled = !shouldEnableMonitoring;
    if (shouldEnableMonitoring) {
      console.log('Initializing monitoring');
      me.metricCounters = settings['metrics'] || {};
      Object.keys(me.metricCounters).map((metric) => {
        try {
          let id = me.metricCounters[metric];
          me.createMetric(metric);
          if (Object.hasOwn(me, metric)) {
            if (me[metric].initMonitoring(id)) {
              me.initializedMetrics.push(metric);
            }
          } else {
            console.log(`No SEO module for ${metric}, skipping`);
          }
        } catch {
          console.warn(`Metric ${metric} failed, skipping`);
        }
      });
      me.ignoredEmails = settings['ignored_emails'] || [];
      if (me.debug) {
        console.log('SEO DEBUG');
      }
    } else {
      console.log('SEO is globally disabled');
    }
  }

  reportVisitParams() {
    if (this.disabled) {
      return;
    }
    const me = this;
    me.initializedMetrics.map((metric) => me[metric].reportVisitParams());
  }
}

const SEO = new SEOModule();

export default SEO;
