import axios from '@/api/axios';

const getAnnouncements = () => axios.get('/announcements');

const getAnnouncementsAll = () => axios.get('/announcements/all');

const createAnnouncement = (data) => axios.post('/announcements', data);

const deleteAnnouncement = (announcementId) => axios.delete(`/announcements/${announcementId}`);

export default {
  getAnnouncements,
  getAnnouncementsAll,
  createAnnouncement,
  deleteAnnouncement,
};
