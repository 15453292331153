<template>
  <v-alert dense text :type="type" v-bind="$attrs">
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: 'AppAlert',
  props: {
    type: {
      required: false,
      type: String,
      default: 'info',
    },
  },
};
</script>
