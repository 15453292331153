import i18n from '@/locale/i18n';

const messages = i18n.messages[i18n.locale];
const blockTitles = messages.dashboardMetadata.titles;

export default {
  metadata: null,
  user: null,
  roadmaps: null,
  roadmapsSummary: null,
  adaptationsSummary: null,
  specsWithGrades360: null,
  historyGrades360: null,
  customBlocks: {
    diagram360: {
      code: 'diagram360',
      name: blockTitles.competencies360,
      isPersonal: false,
      data: null,
    },
    interactionDiagram: {
      code: 'interactionDiagram',
      name: messages.interactionDiagram,
      isPersonal: false,
    },
    feedbacks: {
      code: 'feedbacks',
      name: blockTitles.feedbacks,
      isPersonal: false,
    },
    tests: {
      code: 'tests',
      name: blockTitles.knowledge,
      isPersonal: false,
      data: null,
    },
    targets: {
      code: 'targets',
      name: blockTitles.targets,
      isPersonal: false,
      data: null,
    },
    potential: {
      code: 'potential',
      name: blockTitles.personalPotential,
      isPersonal: true,
      data: null,
    },
    conflictness: {
      code: 'conflictness',
      name: blockTitles.conflictLevel,
      isPersonal: true,
      data: null,
    },
    managementStyle: {
      code: 'managementStyle',
      name: blockTitles.managementStyle,
      isPersonal: true,
      data: null,
    },
    teamRoles: {
      code: 'teamRoles',
      name: blockTitles.teamRoles,
      isPersonal: true,
      data: null,
    },
  },
  isLoading: false,
  isLoadingAdaptationsSummary: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
  banner: null,
};
