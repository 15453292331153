import utilsApi from '@/api/utils';
import {mutationTypes} from '@/store/modules/utils/mutations';

export const actionsTypes = {
  uploadFile: '[utils] upload file',

  getTags: '[company] get tags',
  getBoundTags: '[company] get bound tags',
  bindTags: '[company] post tags for bind',
  bindTag: '[company] post tag for bind',
  createTag: '[company] create tag',
};

const actions = {
  [actionsTypes.uploadFile](context, {files}) {
    return new Promise((resolve, reject) => {
      if (!files.length) {
        return [];
      }
      context.commit(mutationTypes.uploadFileStart);
      const formData = new FormData();
      files.forEach((file, idx) => {
        // на бэке надо чтобы первый файл назывался file, затем остальные с названиями file1, file2, ... и т.д
        formData.append(`file${idx === 0 ? '' : idx}`, file);
      });
      utilsApi
        .uploadFile(formData)
        .then((response) => {
          context.commit(mutationTypes.uploadFileSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.uploadFileFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getBoundTags](context, {id, code, relationTable, relationField, relationValues}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBoundTagsStart);
      utilsApi
        .getBoundTags(id, relationTable, relationField, relationValues)
        .then((response) => {
          context.commit(mutationTypes.getBoundTagsSuccess, {code, payload: response.data});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBoundTagsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getTags](context, {id, search}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTagsStart);
      utilsApi
        .getTags(id, search)
        .then((response) => {
          context.commit(mutationTypes.getTagsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTagsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.bindTags](context, {bindingData}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.bindTagsStart);
      utilsApi
        .bindTags(bindingData)
        .then((response) => {
          context.commit(mutationTypes.bindTagsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.bindTagsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.bindTag](context, {tagId, bindingData}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.bindTagsStart);
      utilsApi
        .bindTag(tagId, bindingData)
        .then((response) => {
          context.commit(mutationTypes.bindTagsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.bindTagsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createTag](context, {tagData}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.bindTagsStart);
      utilsApi
        .createTag(tagData)
        .then((response) => {
          context.commit(mutationTypes.bindTagsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.bindTagsFailure);
          reject(result);
        });
    });
  },
};

export default actions;
