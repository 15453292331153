import axios from '@/api/axios';

const getSEOParams = () => axios.get('/settings/seo_params');

const getInstanceSettings = () => axios.get('/settings/instance');

const getWebsocketUrl = () => axios.get('/get_ws_link');

export default {
  getSEOParams,
  getInstanceSettings,
  getWebsocketUrl,
};
