<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <v-row class="app-left-info" no-gutters>
    <v-col class="app-left-info__image p-0 m-0 vh-100 app-left-info__image-default">
      <v-row no-gutters justify="center" align-content="end" class="h-100">
        <div class="app-left-info__card pa-8">
          <v-row no-gutters>
            <app-logo class="app-left-info__logo" :dark-logo="!isDarkTheme" />
          </v-row>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {themeMixin} from '@/mixins/theme';

export default {
  name: 'AppAuthLeftInfo',
  mixins: [themeMixin],
};
</script>

<style lang="scss" scoped>
.app-left-info {
  &__image {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: center;

    &-default {
      background-repeat: no-repeat;
    }
  }
  &__logo {
    &::v-deep .app-logo__img {
      min-height: rem(88px);
      max-height: rem(120px);
    }
  }
  &__card {
    background-color: transparent !important;
    margin-bottom: 10%;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 10px !important;
  }
  .theme--light & {
    background-color: var(--v-info-base);

    &__image-default {
      background-image: url('@/assets/img/auth-bg-white.svg');
    }
  }
  .theme--dark & {
    &__image-default {
      background-image: url('@/assets/img/auth-bg-black.svg');
    }
  }
}
</style>
