import axios from '@/api/axios';

const getCurrentUser = () => axios.post('/auth/check');

const impersonate = (data) => axios.post('/auth/impersonate', data);

const logout = () => axios.post('/logout');

export default {
  getCurrentUser,
  impersonate,
  logout,
};
