export default {
  metadata: null,
  company: null,
  members: null,
  specializations: null,
  metadataTests: null,
  metadataTests360: null,
  statisticsTest360: null,
  detailedMetadataTests: null,
  isSubmitting: false,
  isLoading: false,
  errors: null,
  validationErrors: null,
};
