export const gettersTypes = {
  count: '[administration] user list count',
};

const getters = {
  [gettersTypes.count]: (state) => {
    return state.count;
  },
};

export default getters;
