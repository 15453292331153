export const routeModules = {
  main: {
    dashboard: [
      'adaptation_enabled',
      'evaluation_enabled',
      'feedback_enabled',
      'ht_tests_enabled',
      'interconnect_enabled',
      'targets_enabled',
      'tests_and_surveys_enabled',
    ],
    resume: [
      'adaptation_enabled',
      'evaluation_enabled',
      'feedback_enabled',
      'ht_tests_enabled',
      'targets_enabled',
      'tests_and_surveys_enabled',
    ],
    companyFeed: ['tests_and_surveys_enabled'],
    targets: {
      targetsSelf: ['targets_enabled'],
    },
    meetings: {
      meetingsParticipant: ['meetings_enabled'],
      meetingsParticipantUser: ['meetings_enabled'],
      meetingsSupervisor: ['meetings_enabled'],
      meetingsCreate: ['meetings_enabled'],
      meetingsDetails: ['meetings_enabled'],
    },
    progress: {
      progressSelf360: ['evaluation_enabled'],
      progressNotSelf360: ['evaluation_enabled'],
      progressApproval360: ['evaluation_enabled'],
      progressSelf: ['evaluation_enabled'],
      progressFeedbacks: ['feedback_enabled'],
      progressPersonal: ['ht_tests_enabled'],
      progressUserSurveys: ['tests_and_surveys_enabled'],
      progressUserTests: ['tests_and_surveys_enabled'],
    },
    curator: {
      curatorAdaptation: ['adaptation_enabled'],
      curatorTargets: ['targets_enabled'],
    },
    report: {
      report360: ['evaluation_enabled'],
      reportSelf: ['evaluation_enabled'],
      reportPersonal: ['ht_tests_enabled'],
      reportTeam: ['evaluation_enabled', 'ht_tests_enabled'],
    },
    roadmap: {
      roadmap360: ['evaluation_enabled'],
      roadmap360ByTest: ['evaluation_enabled'],
      roadmapSelf: ['evaluation_enabled'],
      roadmapBoard: ['evaluation_enabled'],
    },
    board: {
      boardTasks: ['evaluation_enabled'],
      boardAdaptation: ['adaptation_enabled'],
    },
    company: {
      companyFeed: ['tests_and_surveys_enabled'],
      companyStatistic: [
        'adaptation_enabled',
        'evaluation_enabled',
        'feedback_enabled',
        'ht_tests_enabled',
        'interconnect_enabled',
        'targets_enabled',
        'tests_and_surveys_enabled',
      ],
      companyScore: ['evaluation_enabled'],
      companyRoadmaps: ['evaluation_enabled'],
      companyTargets: ['targets_enabled'],
      companySurveys: ['tests_and_surveys_enabled'],
      companySurveysEditMain: ['tests_and_surveys_enabled'],
      companySurveysEditQuestions: ['tests_and_surveys_enabled'],
      companySurveysEditAccess: ['tests_and_surveys_enabled'],
      companySurveysEditMailing: ['tests_and_surveys_enabled'],
      companySurveysSummary: ['tests_and_surveys_enabled'],
      companySurveysDetails: ['tests_and_surveys_enabled'],
      companySurveysAnalyticsStatistic: ['tests_and_surveys_enabled'],
      companySurveysAnalyticsWellbeing: ['tests_and_surveys_enabled'],
      companySurveysAnalyticsBurnoutAtWork: ['tests_and_surveys_enabled'],
      companyFeedbacks: ['feedback_enabled'],
      companyFeedbacksMethodologies: ['feedback_enabled'],
      companyFeedbacksSummary: ['feedback_enabled'],
      companyFeedbacksDetails: ['feedback_enabled'],
      companyFeedbacksUserDetails: ['feedback_enabled'],
      companyTests: ['tests_and_surveys_enabled'],
      companyTestsEditMain: ['tests_and_surveys_enabled'],
      companyTestsEditQuestions: ['tests_and_surveys_enabled'],
      companyTestsEditAccess: ['tests_and_surveys_enabled'],
      companyTestsEditMailing: ['tests_and_surveys_enabled'],
      companyTestsEditScoring: ['tests_and_surveys_enabled'],
      companyResearchmentSurveys: ['tests_and_surveys_enabled'],
      companyResearchmentSurveysDetails: ['tests_and_surveys_enabled'],
      companyResearchmentSurveysSummary: ['tests_and_surveys_enabled'],
      companyResearchmentSurveysTemplates: ['tests_and_surveys_enabled'],
      companyResearchmentSurveysEditMain: ['tests_and_surveys_enabled'],
      companyResearchmentSurveysEditQuestions: ['tests_and_surveys_enabled'],
      companyResearchmentTestsEditMain: ['tests_and_surveys_enabled'],
      companyResearchmentTestsEditQuestions: ['tests_and_surveys_enabled'],
      companyResearchmentTestsEditScoring: ['tests_and_surveys_enabled'],
      companyResearchmentTest: ['tests_and_surveys_enabled'],
      companyResearchmentTestTemplates: ['tests_and_surveys_enabled'],
      companyAdaptation: ['adaptation_enabled'],
      companyAdaptationSettings: ['adaptation_enabled'],
      companyDemoPersonalTests: ['ht_tests_enabled'],
      companyMeetings: ['meetings_enabled'],
    },
    team: {
      teamScore: ['evaluation_enabled', 'ht_tests_enabled'],
      teamRoles: ['ht_tests_enabled'],
      teamMembers: [
        'adaptation_enabled',
        'evaluation_enabled',
        'feedback_enabled',
        'ht_tests_enabled',
        'interconnect_enabled',
        'targets_enabled',
        'tests_and_surveys_enabled',
      ],
    },
  },
};
