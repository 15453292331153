import roadmapApi from '@/api/roadmap';
import {mutationTypes} from '@/store/modules/roadmap/mutations';

export const actionsTypes = {
  getIface: '[roadmap] get iface',
  getVersions: '[roadmap] get versions',
  getVersionsBySpec: '[roadmap] get versions by spec',
  getHeader: '[roadmap] get header',
  getCommonMaterials: '[roadmap] get common materials',
  getSkillsMaterials: '[roadmap] get skills materials',
  getBlocksMaterials: '[roadmap] get blocks materials',
  getBlockProgress: '[roadmap] get block progress',

  recreateRoadmap: '[roadmap] recreate roadmap',
  fixateRoadmap: '[roadmap] fixate roadmap',
  changeHeader: '[roadmap] change header',

  changeMaterial: '[roadmap] change material',
  showMaterials: '[roadmap] show materials',
  deleteMaterials: '[roadmap] delete materials',

  changeBlock: '[roadmap] change block',
};

const actions = {
  [actionsTypes.getIface](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getIfaceStart);
      roadmapApi
        .getIface()
        .then((response) => {
          context.commit(mutationTypes.getIfaceSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getIfaceFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getVersions](context, {userId, company}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getVersionsStart);
      roadmapApi
        .getVersions(userId, company)
        .then((response) => {
          context.commit(mutationTypes.getVersionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getVersionsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getVersionsBySpec](context, {userId, specCode, company}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getVersionsBySpecStart);
      roadmapApi
        .getVersionsBySpec(userId, specCode, company)
        .then((response) => {
          context.commit(mutationTypes.getVersionsBySpecSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getVersionsBySpecFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getHeader](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getHeaderStart);
      roadmapApi
        .getHeader(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getHeaderSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getHeaderFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCommonMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCommonMaterialsStart);
      roadmapApi
        .getCommonMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getCommonMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCommonMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getSkillsMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSkillsMaterialsStart);
      roadmapApi
        .getSkillsMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getSkillsMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSkillsMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getBlocksMaterials](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getBlocksMaterialsStart);
      roadmapApi
        .getBlocksMaterials(id, userId)
        .then((response) => {
          context.commit(mutationTypes.getBlocksMaterialsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getBlocksMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.recreateRoadmap](context, {id, userId, skillType, specId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.recreateRoadmapStart);
      roadmapApi
        .recreateRoadmap(id, userId, skillType, specId)
        .then((response) => {
          context.commit(mutationTypes.recreateRoadmapSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.recreateRoadmapFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.fixateRoadmap](context, {id, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.fixateRoadmapStart);
      roadmapApi
        .fixateRoadmap(id, userId)
        .then((response) => {
          context.commit(mutationTypes.fixateRoadmapSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.fixateRoadmapFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeHeader](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeHeaderStart);
      roadmapApi
        .changeHeader(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeHeaderSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeHeaderFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeMaterial](context, {userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMaterialStart);
      roadmapApi
        .changeMaterial(userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeMaterialSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMaterialFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.showMaterials](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.showMaterialsStart);
      roadmapApi
        .showMaterials(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.showMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.showMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMaterials](context, {id, userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMaterialsStart);
      roadmapApi
        .deleteMaterials(id, userId, data)
        .then((response) => {
          context.commit(mutationTypes.deleteMaterialsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMaterialsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeBlock](context, {userId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeBlockStart);
      roadmapApi
        .changeBlock(userId, data)
        .then((response) => {
          context.commit(mutationTypes.changeBlockSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeBlockFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
