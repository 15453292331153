import axios from '@/api/axios';

// skills
const getSkillsTree = (companyId) =>
  axios.get(`/admin/get_skill_registry_tree${companyId ? `?company_id=${companyId}` : ''}`);

const getSkillsVersions = (companyId) =>
  axios.get(`/admin/skills/version${companyId ? `?company_id=${companyId}` : ''}`);

const fixateSkillsVersion = (companyId) =>
  axios.post(`/admin/skills/version${companyId ? `?company_id=${companyId}` : ''}`);

// registry
const registryMove = (data) => axios.post('/admin/registry/move', data);

const registryEdit = (data) => axios.post('/admin/registry/edit', data);

// materials
const getMaterialsByQuestion = (companyId, questionId) =>
  axios.get(
    `/admin/materials/by_question/${questionId}${companyId ? `?company_id=${companyId}` : ''}`
  );

const createMaterialsByQuestion = (companyId, questionId, data) =>
  axios.post(
    `/admin/materials/by_question/${questionId}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

const deleteMaterialsByQuestion = (companyId, questionId, data) =>
  axios.delete(
    `/admin/materials/by_question/${questionId}${companyId ? `?company_id=${companyId}` : ''}`,
    {data}
  );

const getMaterialsBySkill = (companyId, skillId) =>
  axios.get(`/admin/materials/by_skill/${skillId}${companyId ? `?company_id=${companyId}` : ''}`);

const createMaterialsBySkill = (companyId, skillId, data) =>
  axios.post(
    `/admin/materials/by_skill/${skillId}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

const deleteMaterialsBySkill = (companyId, skillId, data) =>
  axios.delete(
    `/admin/materials/by_skill/${skillId}${companyId ? `?company_id=${companyId}` : ''}`,
    {data}
  );

const getMaterialsBySpec = (specCode, companyId) =>
  axios.get(`/admin/materials/by_spec/${specCode}${companyId ? `?company_id=${companyId}` : ''}`);

const createMaterialsBySpec = (specCode, companyId, data) =>
  axios.post(
    `/admin/materials/by_spec/${specCode}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

const deleteMaterialsBySpec = (specCode, companyId, data) =>
  axios.delete(
    `/admin/materials/by_spec/${specCode}${companyId ? `?company_id=${companyId}` : ''}`,
    {data}
  );

const changeMaterials = (companyId, data) =>
  axios.put(`/admin/materials/edit${companyId ? `?company_id=${companyId}` : ''}`, data);

const changePulseSpec = (data) => axios.post(`/admin/specialization/pulse`, data);

const getMaterialsGrades = (companyId) =>
  axios.get(`/admin/materials/grades${companyId ? `?company_id=${companyId}` : ''}`);

// specializations
const getSpecializations = (companyId) =>
  axios.get(`/admin/specialization${companyId ? `?company_id=${companyId}` : ''}`);

const getAffectedSpecs = (
  params = {company_id: undefined, block_id: undefined, skill_id: undefined, question_id: undefined}
) => axios.get(`/admin/item/specialization`, {params});

const createSpecialization = (companyId, data) =>
  axios.post(`/admin/specialization${companyId ? `?company_id=${companyId}` : ''}`, data);

const editSpecialization = (specId, companyId, data) =>
  axios.put(`/admin/specialization/${specId}${companyId ? `?company_id=${companyId}` : ''}`, data);

const deleteSpecialization = (specId, companyId) =>
  axios.delete(`/admin/specialization/${specId}${companyId ? `?company_id=${companyId}` : ''}`);

const importSpecializations = (companyId, data) =>
  axios.post(`/admin/specializations/file${companyId ? `?company_id=${companyId}` : ''}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

// specializations group
const getSpecializationGroups = (companyId) =>
  axios.get(`/admin/specialization_group${companyId ? `?company_id=${companyId}` : ''}`);

const createSpecializationGroups = (companyId, data) =>
  axios.post(`/admin/specialization_group${companyId ? `?company_id=${companyId}` : ''}`, data);

const editSpecializationGroups = (companyId, groupId, data) =>
  axios.put(
    `/admin/specialization_group/${groupId}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

const deleteSpecializationGroup = (companyId, groupId) =>
  axios.delete(
    `/admin/specialization_group/${groupId}${companyId ? `?company_id=${companyId}` : ''}`
  );

// base profile tree
const getBaseProfileTree = (companyId, specCode) =>
  axios.get(
    `/admin/get_base_profile_tree/${specCode}${companyId ? `?company_id=${companyId}` : ''}`
  );

const exportBaseProfileTree = (companyId, specCode) =>
  axios.get(
    `/admin/get_base_profile_tree/${specCode}/export${companyId ? `?company_id=${companyId}` : ''}`,
    {
      responseType: 'blob',
    }
  );

// itemType: skill_block, skill, question
const moveBaseProfileTree = (companyId, itemType, data) =>
  axios.post(
    `/admin/baseprofiles/move/${itemType}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

const addBaseProfile = (data) => axios.post(`/admin/baseprofiles/add`, data);

const editBaseProfile = (data) => axios.post(`/admin/baseprofiles/edit`, data);

const deleteBaseProfile = (data) => axios.post(`/admin/baseprofiles/delete`, data);

const deleteBaseProfileSkills = (companyId, specCode, data) =>
  axios.put(
    `/admin/baseprofile/delete/${specCode}${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

// scales
const changeScales = (companyId, data) =>
  axios.put(`/admin/specialization/scales${companyId ? `?company_id=${companyId}` : ''}`, data);

const getQuestionScale = (companyId, questionId, specCode) =>
  axios.get(
    `/admin/question/${questionId}/spec/${specCode}/scale${
      companyId ? `?company_id=${companyId}` : ''
    }`
  );

const changeQuestionScale = (companyId, questionId, specCode, data) =>
  axios.post(
    `/admin/question/${questionId}/spec/${specCode}/scale${
      companyId ? `?company_id=${companyId}` : ''
    }`,
    data
  );

// tooltips
const getQuestionTooltips = (companyId, questionId) =>
  axios.get(`/admin/question/${questionId}/tooltips${companyId ? `?company_id=${companyId}` : ''}`);

const createQuestionTooltips = (companyId, questionId, data) =>
  axios.post(
    `/admin/question/${questionId}/tooltips${companyId ? `?company_id=${companyId}` : ''}`,
    data
  );

export default {
  getSkillsTree,
  getSkillsVersions,
  fixateSkillsVersion,

  registryMove,
  registryEdit,

  getMaterialsByQuestion,
  createMaterialsByQuestion,
  deleteMaterialsByQuestion,

  getMaterialsBySkill,
  createMaterialsBySkill,
  deleteMaterialsBySkill,

  getMaterialsBySpec,
  createMaterialsBySpec,
  deleteMaterialsBySpec,

  changeMaterials,
  changePulseSpec,
  getMaterialsGrades,

  getSpecializations,
  getAffectedSpecs,
  createSpecialization,
  editSpecialization,
  deleteSpecialization,
  importSpecializations,

  getSpecializationGroups,
  createSpecializationGroups,
  editSpecializationGroups,
  deleteSpecializationGroup,

  getBaseProfileTree,
  exportBaseProfileTree,
  moveBaseProfileTree,
  editBaseProfile,
  addBaseProfile,
  deleteBaseProfile,
  deleteBaseProfileSkills,

  changeScales,
  getQuestionScale,
  changeQuestionScale,

  getQuestionTooltips,
  createQuestionTooltips,
};
