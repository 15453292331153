<template>
  <v-footer class="app-footer mt-10">
    <v-container fluid>
      <v-divider></v-divider>
      <div class="mt-5">©&nbsp;Beehive,&nbsp;{{ currentYear }}</div>
    </v-container>
  </v-footer>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppFooter',
  computed: {
    currentYear() {
      return moment().format('YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
.app-footer.v-footer {
  background-color: transparent;
}
</style>
