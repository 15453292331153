import moment from 'moment';
import i18n from '@/locale/i18n';

const $t = i18n.messages[i18n.locale].validators;

export function required(v) {
  return Array.isArray(v) ? !!v.length || $t.required : !!v || $t.required;
}

export function numeric(v) {
  return (!isNaN(Number(v)) && v !== '') || $t.numeric;
}

export function positiveInt(v) {
  const pattern = /^[1-9]\d*$/;
  return v ? pattern.test(v) || $t.positiveInt : true;
}

export function positiveIntWithZero(v) {
  const pattern = /^[0-9]\d*$/;
  return v ? pattern.test(v) || $t.positiveIntWithZero : true;
}

export function phone(v) {
  const pattern =
    /^ *(?:\+?(\d{1,3})[- .]?)?(?:(?:(\d{3})|\((\d{3})\))?[- .]?)(?:([0-9]\d{2})[- .]?)(\d{4})(?: *(?:e?xt?) *(\d*))? *$/;
  return v ? pattern.test(v) || $t.phone : true;
}

export function email(v) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return v ? pattern.test(v) || $t.email : true;
}

export function maxLength(v, maxLimit = 25) {
  return v ? v.length <= maxLimit || `${$t.maxLength} ${maxLimit}` : true;
}

export function minLength(v, minLimit = 6) {
  return v ? v.length >= minLimit || $t.minLength.replace('{minLimit}', String(minLimit)) : true;
}

export function minValue(v, minLimit, message = $t.minValue) {
  return v ? v >= minLimit || message.replace('{minLimit}', String(minLimit)) : true;
}

export function maxValue(v, maxLimit, message = $t.maxValue) {
  return v ? v <= maxLimit || message.replace('{maxLimit}', String(maxLimit)) : true;
}

export function pastDate(v, dateFormat = 'YYYY-MM-DD') {
  const date = moment(v, dateFormat, true);
  return v ? (date.isValid() ? moment().isAfter(date) : false) : true;
}

export function futureDate(v, dateFormat = 'YYYY-MM-DD') {
  const date = moment(v, dateFormat, true);
  const today = moment().format(dateFormat);
  return v ? (date.isValid() ? !moment(today, dateFormat).isAfter(date) : false) : true;
}

export function isDateValid(v, dateFormat) {
  return v ? moment(v, dateFormat, i18n.locale, true).isValid() : true;
}

export function url(v) {
  const pattern =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return v ? pattern.test(v) || $t.url : true;
}

export function file(v, limit = 50000000) {
  return !v || v.size < limit || `${$t.file} ${limit * 0.000001} ${$t.megabytes}`;
}

export function isUnique(v, array, field) {
  if (v === '') return true;

  let count = 0;
  array.forEach((el) => {
    if (v === el[field]) ++count;
  });
  return count < 2 || $t.unique;
}

export function notMatch(v1, v2) {
  return v1 ? v1 !== v2 || $t.notMatch : true;
}
