export const mutationTypes = {
  getIfaceStart: '[roadmap360] get iface start',
  getIfaceSuccess: '[roadmap360] get iface success',
  getIfaceFailure: '[roadmap360] get iface failure',

  getVersionsStart: '[roadmap360] get versions start',
  getVersionsSuccess: '[roadmap360] get versions success',
  getVersionsFailure: '[roadmap360] get versions failure',

  getVersionsBySpecStart: '[roadmap360] get versions by spec start',
  getVersionsBySpecSuccess: '[roadmap360] get versions by spec success',
  getVersionsBySpecFailure: '[roadmap360] get versions by spec failure',

  getHeaderStart: '[roadmap360] get header start',
  getHeaderSuccess: '[roadmap360] get header success',
  getHeaderFailure: '[roadmap360] get header failure',

  getSkillsStart: '[roadmap360] get skills start',
  getSkillsSuccess: '[roadmap360] get skills success',
  getSkillsFailure: '[roadmap360] get skills failure',

  getHeaderByTestingIdStart: '[roadmap360] get header by testing id start',
  getHeaderByTestingIdSuccess: '[roadmap360] get header by testing id success',
  getHeaderByTestingIdFailure: '[roadmap360] get header by testing id failure',

  getCommonMaterialsStart: '[roadmap360] get common materials start',
  getCommonMaterialsSuccess: '[roadmap360] get common materials success',
  getCommonMaterialsFailure: '[roadmap360] get common materials failure',

  getSkillsMaterialsStart: '[roadmap360] get skills materials start',
  getSkillsMaterialsSuccess: '[roadmap360] get skills materials success',
  getSkillsMaterialsFailure: '[roadmap360] get skills materials failure',

  getBlocksMaterialsStart: '[roadmap360] get blocks materials start',
  getBlocksMaterialsSuccess: '[roadmap360] get blocks materials success',
  getBlocksMaterialsFailure: '[roadmap360] get blocks materials failure',

  getBlockProgressStart: '[roadmap360] get block progress start',
  getBlockProgressSuccess: '[roadmap360] get block progress success',
  getBlockProgressFailure: '[roadmap360] get block progress failure',

  createRoadmapStart: '[roadmap360] create roadmap start',
  createRoadmapSuccess: '[roadmap360] create roadmap success',
  createRoadmapFailure: '[roadmap360] create roadmap failure',

  fixateRoadmapStart: '[roadmap360] fixate roadmap start',
  fixateRoadmapSuccess: '[roadmap360] fixate roadmap success',
  fixateRoadmapFailure: '[roadmap360] fixate roadmap failure',

  changeHeaderStart: '[roadmap360] change header start',
  changeHeaderSuccess: '[roadmap360] change header success',
  changeHeaderFailure: '[roadmap360] change header failure',

  createMaterialStart: '[roadmap360] create material start',
  createMaterialSuccess: '[roadmap360] create material success',
  createMaterialFailure: '[roadmap360] create material failure',

  moveMaterialsStart: '[roadmap360] move materials start',
  moveMaterialsSuccess: '[roadmap360] move materials success',
  moveMaterialsFailure: '[roadmap360] move materials failure',

  showMaterialsStart: '[roadmap360] show materials start',
  showMaterialsSuccess: '[roadmap360] show materials success',
  showMaterialsFailure: '[roadmap360] show materials failure',

  changeMaterialStart: '[roadmap360] change material start',
  changeMaterialSuccess: '[roadmap360] change material success',
  changeMaterialFailure: '[roadmap360] change material failure',

  deleteMaterialsStart: '[roadmap360] delete materials start',
  deleteMaterialsSuccess: '[roadmap360] delete materials success',
  deleteMaterialsFailure: '[roadmap360] delete materials failure',

  changeBlockStart: '[roadmap360] change block start',
  changeBlockSuccess: '[roadmap360] change block success',
  changeBlockFailure: '[roadmap360] change block failure',
};

const mutations = {
  [mutationTypes.getIfaceStart](state) {
    state.isLoading = true;
    state.iface = null;
  },
  [mutationTypes.getIfaceSuccess](state, payload) {
    state.isLoading = false;
    state.iface = payload;
  },
  [mutationTypes.getIfaceFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getVersionsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getVersionsSuccess](state, payload) {
    state.isLoading = false;
    state.versions = payload;
  },
  [mutationTypes.getVersionsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getVersionsBySpecStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getVersionsBySpecSuccess](state, payload) {
    state.isLoading = false;
    state.versionsBySpec = payload;
  },
  [mutationTypes.getVersionsBySpecFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getHeaderStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getHeaderSuccess](state, payload) {
    state.isLoading = false;
    state.header = payload;
  },
  [mutationTypes.getHeaderFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getSkillsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getSkillsSuccess](state, payload) {
    state.isLoading = false;
    state.skills = payload;
  },
  [mutationTypes.getSkillsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getHeaderByTestingIdStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getHeaderByTestingIdSuccess](state, payload) {
    state.isLoading = false;
    state.header = payload;
  },
  [mutationTypes.getHeaderByTestingIdFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getCommonMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getCommonMaterialsSuccess](state, payload) {
    state.isLoading = false;
    state.commonMaterials = payload;
  },
  [mutationTypes.getCommonMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getSkillsMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getSkillsMaterialsSuccess](state, payload) {
    state.isLoading = false;
    state.skillsMaterials = payload;
  },
  [mutationTypes.getSkillsMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getBlocksMaterialsStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getBlocksMaterialsSuccess](state, payload) {
    state.blocksMaterials = payload;
    state.isLoading = false;
  },
  [mutationTypes.getBlocksMaterialsFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.getBlockProgressStart](state) {
    state.isLoading = true;
    state.errors = null;
  },
  [mutationTypes.getBlockProgressSuccess](state, payload) {
    state.isLoading = false;
    state.blockProgress = payload;
  },
  [mutationTypes.getBlockProgressFailure](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createRoadmapStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createRoadmapSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createRoadmapFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.fixateRoadmapStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.fixateRoadmapSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.fixateRoadmapFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeHeaderStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeHeaderSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeHeaderFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createMaterialStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMaterialSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMaterialFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.moveMaterialsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.moveMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.moveMaterialsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.showMaterialsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.showMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.showMaterialsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMaterialStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMaterialSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMaterialFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMaterialsStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteMaterialsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMaterialsFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.changeBlockStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeBlockSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeBlockFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
};

export default mutations;
