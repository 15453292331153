import variables from '@/helpers/variables';
import {hashStr, shootConfetti} from '@/helpers/utils';

const BH = {};

BH.sezame = (password) => {
  const res = variables.sezamePassword === hashStr(password);
  variables.isSezameOpened = res;
  if (res) shootConfetti();
};

window.BH = BH;
