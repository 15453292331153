<template>
  <v-row
    v-if="fieldTitle"
    no-gutters
    class="w-100 align-center app-text-size--extra-small text--secondary"
  >
    <span class="controls-labels">{{ fieldTitle }}</span>

    <field-hint
      v-if="showTooltip"
      :tooltip="fieldTitleTooltip"
      :icon="icon"
      :icon-color="iconColor"
    />

    <slot name="label-controls"></slot>
  </v-row>
</template>

<script>
import FieldHint from './FieldHint';
export default {
  name: 'AppFieldTitleWithTooltip',

  components: {FieldHint},

  props: {
    fieldTitle: {
      type: String,
      default: undefined,
    },

    fieldTitleTooltip: {
      type: String,
      default: undefined,
    },

    icon: {
      type: String,
      default: 'mdi-information-outline',
    },

    iconColor: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    showTooltip() {
      return this.fieldTitleTooltip && this.fieldTitleTooltip !== '-';
    },
  },
};
</script>

<style></style>
