<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <router-link :to="{name: $routeNames.main.profile}" :class="classes">
    <figure class="app-logo d-flex align-center justify-center">
      <img
        :src="customLogo ? customLogo : getImgUrl(defaultLogo)"
        alt="logotype"
        class="app-logo__img"
      />
    </figure>
  </router-link>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'AppLogo',
  props: {
    classes: {
      required: false,
      type: String,
      default: null,
    },
    darkLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultLogo: this.darkLogo ? 'logo.svg' : 'logo-white.svg',
    };
  },
  computed: {
    ...mapState({
      instance: (state) => state.settings.instance,
    }),
    customLogo() {
      const {instance} = this;
      const {path} = this.$route;

      if (!instance) return null;

      return (path.startsWith('/authentication') && instance.auth_logo_url) || instance.logo_url;
    },
  },
  methods: {
    getImgUrl(name) {
      return require(`@/assets/img/${name}`);
    },
  },
};
</script>

<style scoped lang="scss">
.app-logo {
  &__img {
    width: 100%;
    height: auto;
    max-height: rem(80px);
    object-fit: contain;
  }
}
</style>
