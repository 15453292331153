import axios from '@/api/axios';

const getIface = () => axios.get('/roadmap360/iface');

const getReport = (id, userId) => axios.get(`/roadmap360/${userId}/report/${id}`);

const getVersions = (userId, company) =>
  axios.get(`/roadmap360/${userId}${company ? `?company=${company}` : ''}`);

const getVersionsBySpec = (userId, specCode, company) =>
  axios.get(`/roadmap360/${userId}/${specCode}${company ? `?company=${company}` : ''}`);

const getHeaderByTestingId = (testing360Id) => axios.get(`/roadmap360/test/${testing360Id}`);

const getHeader = (id, userId) => axios.get(`/roadmap360/${userId}/header/${id}`);

const getSkills = (id, userId, companyId) =>
  axios.get(
    `/roadmap360/${id}/user/${userId}/skills${companyId ? `?company_id=${companyId}` : ''}`
  );

const getCommonMaterials = (id, userId) =>
  axios.get(`/roadmap360/${userId}/materials/${id}/common`);

const getSkillsMaterials = (id, userId) =>
  axios.get(`/roadmap360/${userId}/materials/${id}/skills`);

const getBlocksMaterials = (id, userId) =>
  axios.get(`/roadmap360/${userId}/materials/${id}/blocks`);

const getBlockProgress = (id, userId, blockId) =>
  axios.get(`/roadmap360/${userId}/${id}/block/${blockId}/progress`);

const createRoadmap = (testing360Id, recreate) =>
  axios.post(`/roadmap360/${testing360Id}${recreate ? `?recreate=${recreate}` : ''}`);

const fixateRoadmap = (id, userId) => axios.post(`/roadmap360/${userId}/header/${id}/fixate`);

const changeHeader = (id, userId, data) => axios.put(`/roadmap360/${userId}/header/${id}`, data);

// materials
const createMaterial = (id, userId, data) =>
  axios.put(`/roadmap360/${userId}/materials/${id}/manual`, data);

const moveMaterials = (id, userId, data) =>
  axios.put(`/roadmap360/${userId}/materials/${id}/move`, data);

const showMaterials = (id, userId, data) =>
  axios.put(`/roadmap360/${userId}/materials/${id}/show`, data);

const changeMaterial = (userId, data) => axios.put(`/roadmap360/${userId}/material`, data);

const deleteMaterials = (id, userId, data) =>
  axios.put(`/roadmap360/${userId}/materials/${id}/delete`, data);

const changeBlock = (userId, data) => axios.put(`/roadmap360/${userId}/block`, data);

export default {
  getIface,
  getReport,
  getVersions,
  getVersionsBySpec,
  getSkills,
  getHeader,
  getHeaderByTestingId,
  getCommonMaterials,
  getSkillsMaterials,
  getBlocksMaterials,
  getBlockProgress,

  createRoadmap,
  fixateRoadmap,
  changeHeader,

  createMaterial,
  moveMaterials,
  showMaterials,
  changeMaterial,
  deleteMaterials,

  changeBlock,
};
