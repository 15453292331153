import navigationApi from '@/api/navigation';
import {mutationTypes} from '@/store/modules/navigation/mutations';

export const actionsTypes = {
  getSettings: '[navigation] get settings',
  getPresence: '[navigation] get presence',
};

const actions = {
  [actionsTypes.getSettings](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSettingsStart);
      navigationApi
        .getSettings(companyId)
        .then((response) => {
          context.commit(mutationTypes.getSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSettingsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getPresence](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getPresenceStart);
      navigationApi
        .getPresence()
        .then((response) => {
          context.commit(mutationTypes.getPresenceSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getPresenceFailure);
          reject(result);
        });
    });
  },
};

export default actions;
