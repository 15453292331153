import authApi from '@/api/auth';
import {mutationTypes} from '@/store/modules/auth/mutations';

export const actionsTypes = {
  logout: '[auth] logout',
  impersonate: '[auth] impersonate',
  getCurrentUser: '[auth] get current user',
};

const actions = {
  [actionsTypes.logout](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.logoutStart);
      authApi
        .logout()
        .then((response) => {
          context.commit(mutationTypes.logoutSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.logoutFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.impersonate](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.impersonateStart);
      authApi
        .impersonate(data)
        .then(() => {
          context.commit(mutationTypes.impersonateSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(
            mutationTypes.impersonateFailure,
            result.response.data.message || result.response.data.detail
          );
          reject(result);
        });
    });
  },
  [actionsTypes.getCurrentUser](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentUserStart);
      authApi
        .getCurrentUser()
        .then((response) => {
          context.commit(mutationTypes.getCurrentUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCurrentUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
