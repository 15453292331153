import meetingApi from '@/api/meeting';
import {mutationTypes} from '@/store/modules/meeting/mutations';

export const actionsTypes = {
  getMeetings: '[meeting] get meetings',
  getMeeting: '[meeting] get meeting',
  getQuestions: '[meeting] get questions',
  getQuestionsDirectory: '[meeting] get questions directory',
  getComments: '[meeting] get comments',
  getTasks: '[meeting] get tasks',

  createMeeting: '[meeting] create meeting',
  changeMeeting: '[meeting] change meeting',
  rescheduleMeeting: '[meeting] reschedule meeting',
  doneMeeting: '[meeting] done meeting',
  cancelMeeting: '[meeting] cancel meeting',

  addQuestions: '[meeting] add questions',
  addComment: '[meeting] add comment',
  createTask: '[meeting] create task',
  changeTask: '[meeting] change task',
  changeShowTask: '[meeting] change show task',
  moveTask: '[meeting] move task',
  deleteTask: '[meeting] delete task',
};

const actions = {
  [actionsTypes.getMeetings](context, {userId, role}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMeetingsStart);
      meetingApi
        .getMeetings(userId, role)
        .then((response) => {
          context.commit(mutationTypes.getMeetingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMeetingsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getMeeting](context, meetingId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMeetingStart);
      meetingApi
        .getMeeting(meetingId)
        .then((response) => {
          context.commit(mutationTypes.getMeetingSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMeetingFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getQuestions](context, meetingId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getQuestionsStart);
      meetingApi
        .getQuestions(meetingId)
        .then((response) => {
          context.commit(mutationTypes.getQuestionsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getQuestionsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getQuestionsDirectory](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getQuestionsDirectoryStart);
      meetingApi
        .getQuestionsDirectory()
        .then((response) => {
          context.commit(mutationTypes.getQuestionsDirectorySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getQuestionsDirectoryFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getComments](context, meetingId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCommentsStart);
      meetingApi
        .getComments(meetingId)
        .then((response) => {
          context.commit(mutationTypes.getCommentsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCommentsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getTasks](context, meetingId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTasksStart);
      meetingApi
        .getTasks(meetingId)
        .then((response) => {
          context.commit(mutationTypes.getTasksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTasksFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.createMeeting](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMeetingStart);
      meetingApi
        .createMeeting(data)
        .then((response) => {
          context.commit(mutationTypes.createMeetingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMeetingFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeMeeting](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMeetingStart);
      meetingApi
        .changeMeeting(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.changeMeetingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMeetingFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.rescheduleMeeting](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.rescheduleMeetingStart);
      meetingApi
        .rescheduleMeeting(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.rescheduleMeetingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.rescheduleMeetingFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.doneMeeting](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.doneMeetingStart);
      meetingApi
        .doneMeeting(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.doneMeetingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.doneMeetingFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.cancelMeeting](context, meetingId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.cancelMeetingStart);
      meetingApi
        .cancelMeeting(meetingId)
        .then((response) => {
          context.commit(mutationTypes.cancelMeetingSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.cancelMeetingFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.addQuestions](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.addQuestionsStart);
      meetingApi
        .addQuestions(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.addQuestionsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.addQuestionsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.addComment](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.addCommentStart);
      meetingApi
        .addComment(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.addCommentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.addCommentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createTask](context, {meetingId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTaskStart);
      meetingApi
        .createTask(meetingId, data)
        .then((response) => {
          context.commit(mutationTypes.createTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeTask](context, {meetingId, taskId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTaskStart);
      meetingApi
        .changeTask(meetingId, taskId, data)
        .then((response) => {
          context.commit(mutationTypes.changeTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeShowTask](context, {meetingId, taskId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeShowTaskStart);
      meetingApi
        .changeShowTask(meetingId, taskId, data)
        .then((response) => {
          context.commit(mutationTypes.changeShowTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeShowTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.moveTask](context, {meetingId, taskId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.moveTaskStart);
      meetingApi
        .moveTask(meetingId, taskId, data)
        .then((response) => {
          context.commit(mutationTypes.moveTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.moveTaskFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTask](context, {meetingId, taskId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTaskStart);
      meetingApi
        .deleteTask(meetingId, taskId)
        .then((response) => {
          context.commit(mutationTypes.deleteTaskSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTaskFailure);
          reject(result);
        });
    });
  },
};

export default actions;
