<template>
  <svg
    :width="width"
    :height="height"
    :class="className"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use :xlink:href="iconPath"></use>
    <slot name="defs"></slot>
  </svg>
</template>

<script>
export default {
  name: 'AppSvgIcon',
  props: {
    name: {
      required: false,
      type: String,
      default: null,
    },
    width: {
      required: false,
      type: String,
      default: '1rem',
    },
    height: {
      required: false,
      type: String,
      default: '1rem',
    },
    size: {
      required: false,
      type: String,
      default: null,
    },
    viewBox: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    iconPath() {
      return require(`@/assets/svg/${this.name}.svg`).default.url;
    },
    className() {
      return `v-icon v-icon--${this.name} ${this.size ? `v-size--${this.size}` : ''} v-icon__svg`;
    },
  },
};
</script>
