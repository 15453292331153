export const gettersTypes = {
  currentUser: '[auth] currentUser',
  userId: '[auth] userId',
  email: '[auth] email',
  companyId: '[auth] companyId',
  isLoggedIn: '[auth] isLoggedIn',
  isAnonymous: '[auth] isAnonymous',
};

const getters = {
  [gettersTypes.currentUser]: (state) => {
    if (!state.currentUser) return {};
    return state.currentUser;
  },
  [gettersTypes.userId]: (state) => {
    if (!state.currentUser) return 0;
    return state.currentUser.user_id;
  },
  [gettersTypes.email]: (state) => {
    if (!state.currentUser) return 0;
    return state.currentUser.email;
  },
  [gettersTypes.companyId]: (state) => {
    if (!state.currentUser) return 0;
    return state.currentUser.company_id;
  },
  [gettersTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn);
  },
  [gettersTypes.isAnonymous]: (state) => {
    return state.isLoggedIn === false;
  },
};

export default getters;
