import targetApi from '@/api/target';
import {mutationTypes} from '@/store/modules/target/mutations';

export const actionsTypes = {
  createTargetAsUser: '[target] create target as user',
  createTargetAsCurator: '[target] create target as curator',

  changeTarget: '[target] change target',
  gradeTarget: '[target] grade target',
  moveTarget: '[target] move target',
  deleteTarget: '[target] delete target',
  changeCurators: '[target] change curators',

  getComments: '[target] get comments',
  getRights: '[target] get rights',
};

const actions = {
  [actionsTypes.createTargetAsUser](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTargetAsUserStart);
      targetApi
        .createTargetAsUser(data)
        .then((response) => {
          context.commit(mutationTypes.createTargetAsUserSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTargetAsUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createTargetAsCurator](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTargetAsCuratorStart);
      targetApi
        .createTargetAsCurator(data)
        .then((response) => {
          context.commit(mutationTypes.createTargetAsCuratorSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTargetAsCuratorFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.changeTarget](context, {targetId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTargetStart);
      targetApi
        .changeTarget(targetId, data)
        .then((response) => {
          context.commit(mutationTypes.changeTargetSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTargetFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.gradeTarget](context, {targetId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.gradeTargetStart);
      targetApi
        .gradeTarget(targetId, data)
        .then((response) => {
          context.commit(mutationTypes.gradeTargetSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.gradeTargetFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.moveTarget](context, {targetId, status}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.moveTargetStart);
      targetApi
        .moveTarget(targetId, status)
        .then((response) => {
          context.commit(mutationTypes.moveTargetSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.moveTargetFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteTarget](context, targetId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteTargetStart);
      targetApi
        .deleteTarget(targetId)
        .then((response) => {
          context.commit(mutationTypes.deleteTargetSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteTargetFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeCurators](context, {targetId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeCuratorsStart);
      targetApi
        .changeCurators(targetId, data)
        .then((response) => {
          context.commit(mutationTypes.changeCuratorsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeCuratorsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getComments](context, targetId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCommentsStart);
      targetApi
        .getComments(targetId)
        .then((response) => {
          context.commit(mutationTypes.getCommentsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCommentsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getRights](context, targetId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRightsStart);
      targetApi
        .getRights(targetId)
        .then((response) => {
          context.commit(mutationTypes.getRightsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRightsFailure);
          reject(result);
        });
    });
  },
};

export default actions;
