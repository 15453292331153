import en from './en';
import ru from './ru';

import enCompanySurveysAnalytics from './company/surveys/analytics/en';
import ruCompanySurveysAnalytics from './company/surveys/analytics/ru';

const messages = {
  en: {
    ...en,
    ...enCompanySurveysAnalytics,
  },
  ru: {
    ...ru,
    ...ruCompanySurveysAnalytics,
  },
};
/* Ключи в компонентах приоритетны. Если там записи нет а здесь есть -
   будет сообщение отсюда, иначе из компонента */
export default messages;
