import teamApi from '@/api/team';
import {mutationTypes} from '@/store/modules/team/mutations';

export const actionsTypes = {
  getTeam: '[team] get metadata',
  getCompany: '[team] get company',
  getSpecializations: '[team] get specializations',

  getMembers: '[team] get members',
  addMember: '[team] add member',
  addMembers: '[team] add members',
  deleteMember: '[team] delete member',

  changeLeaderAttr: '[team] change leader attribute',

  getMetadataTests: '[team] get metadata tests',
  getMetadataTests360: '[team] get metadata tests 360',
  getStatisticsTest360: '[team] get statistics test 360',
  getDetailedMetadataTests: '[team] get detailed metadata tests',

  createTests360: '[team] create tests 360',
  getExpertsTest360: '[team] get experts for test 360',
  changeTest360: '[team] change test 360',
  changeStatusTest360: '[team] change status test 360',
  changePeriodTest360: '[team] change period test 360',
  reEvalLockOfTests360: '[team] re-evaluation lock of tests 360',
  setHiddenReports360: '[team] set hidden reports 360',
  exportTests360: '[team] export tests 360',
  exportRoadmaps360: '[team] export roadmaps 360',

  dryRunRemindersTests360: '[team] dry run reminders about tests 360',
  dryRunRemindersSelfTests: '[team] dry run reminders about self tests',
  dryRunRemindersPersonalTests: '[team] dry run reminders about personal tests',

  createRemindersTests360: '[team] create reminders about tests 360',
  createRemindersSelfTests: '[team] create reminders about self tests',
  createRemindersPersonalTests: '[team] create reminders about personal tests',
};

const actions = {
  [actionsTypes.getTeam](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTeamStart);
      teamApi
        .getTeam(id)
        .then((response) => {
          context.commit(mutationTypes.getTeamSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getCompany](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCompanyStart);
      teamApi
        .getCompany(id)
        .then((response) => {
          context.commit(mutationTypes.getCompanySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCompanyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSpecializations](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSpecializationsStart);
      teamApi
        .getSpecializations(id)
        .then((response) => {
          context.commit(mutationTypes.getSpecializationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSpecializationsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getMembers](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMembersStart);
      teamApi
        .getMembers(id)
        .then((response) => {
          context.commit(mutationTypes.getMembersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMembersFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.addMember](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.addMemberStart);
      teamApi
        .addMember(id, data)
        .then((response) => {
          context.commit(mutationTypes.addMemberSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.addMemberFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.addMembers](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.addMembersStart);
      teamApi
        .addMembers(id, data)
        .then((response) => {
          context.commit(mutationTypes.addMembersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.addMembersFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMember](context, {id, employeeId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMemberStart);
      teamApi
        .deleteMember(id, employeeId)
        .then((response) => {
          context.commit(mutationTypes.deleteMemberSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMemberFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.changeLeaderAttr](context, {id, employeeId, leadMark}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeLeaderAttrStart);
      teamApi
        .changeLeaderAttr(id, employeeId, leadMark)
        .then((response) => {
          context.commit(mutationTypes.changeLeaderAttrSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeLeaderAttrFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getMetadataTests](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataTestsStart);
      teamApi
        .getMetadataTests(id)
        .then((response) => {
          context.commit(mutationTypes.getMetadataTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataTestsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getMetadataTests360](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMetadataTests360Start);
      teamApi
        .getMetadataTests360(id)
        .then((response) => {
          context.commit(mutationTypes.getMetadataTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMetadataTests360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.getStatisticsTest360](context, {id, testId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getStatisticsTest360Start);
      teamApi
        .getStatisticsTest360(id, testId)
        .then((response) => {
          context.commit(mutationTypes.getStatisticsTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getStatisticsTest360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.getDetailedMetadataTests](context, id) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDetailedMetadataTestsStart);
      teamApi
        .getDetailedMetadataTests(id)
        .then((response) => {
          context.commit(mutationTypes.getDetailedMetadataTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getDetailedMetadataTestsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.createTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createTests360Start);
      teamApi
        .createTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.createTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getExpertsTest360](context, {id, userId, is180}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getExpertsTest360Start);
      teamApi
        .getExpertsTest360(id, userId, is180)
        .then((response) => {
          context.commit(mutationTypes.getExpertsTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getExpertsTest360Failure);
          reject(result);
        });
    });
  },
  [actionsTypes.changeTest360](context, {id, testId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTest360Start);
      teamApi
        .changeTest360(id, testId, data)
        .then((response) => {
          context.commit(mutationTypes.changeTest360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeStatusTest360](context, {id, testing360Id, status}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeStatusTest360Start);
      teamApi
        .changeStatusTest360(id, testing360Id, status)
        .then((response) => {
          context.commit(mutationTypes.changeStatusTest360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeStatusTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changePeriodTest360](context, {id, testId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changePeriodTest360Start);
      teamApi
        .changePeriodTest360(id, testId, data)
        .then((response) => {
          context.commit(mutationTypes.changePeriodTest360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changePeriodTest360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.reEvalLockOfTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.reEvalLockOfTests360Start);
      teamApi
        .reEvalLockOfTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.reEvalLockOfTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.reEvalLockOfTests360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.setHiddenReports360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setHiddenReports360Start);
      teamApi
        .setHiddenReports360(id, data)
        .then((response) => {
          context.commit(mutationTypes.setHiddenReports360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setHiddenReports360Failure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.exportTests360](context, {id, type, dateFrom, dateTo, email, is_async}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportTests360Start);
      teamApi
        .exportTests360(id, type, dateFrom, dateTo, email, is_async)
        .then((response) => {
          context.commit(mutationTypes.exportTests360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportTests360Failure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.exportRoadmaps360](context, {id, dateFrom, dateTo}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportRoadmaps360Start);
      teamApi
        .exportRoadmaps360(id, dateFrom, dateTo)
        .then((response) => {
          context.commit(mutationTypes.exportRoadmaps360Success);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportRoadmaps360Failure, result.response.status);
          reject(result);
        });
    });
  },

  [actionsTypes.dryRunRemindersTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersTests360Start);
      teamApi
        .dryRunRemindersTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersTests360Success, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersTests360Failure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.dryRunRemindersSelfTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersSelfTestsStart);
      teamApi
        .dryRunRemindersSelfTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersSelfTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersSelfTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.dryRunRemindersPersonalTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.dryRunRemindersPersonalTestsStart);
      teamApi
        .dryRunRemindersPersonalTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.dryRunRemindersPersonalTestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.dryRunRemindersPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },

  [actionsTypes.createRemindersTests360](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersTests360Start);
      teamApi
        .createRemindersTests360(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersTests360Success);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersTests360Failure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.createRemindersSelfTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersSelfTestsStart);
      teamApi
        .createRemindersSelfTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersSelfTestsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersSelfTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
  [actionsTypes.createRemindersPersonalTests](context, {id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createRemindersPersonalTestsStart);
      teamApi
        .createRemindersPersonalTests(id, data)
        .then((response) => {
          context.commit(mutationTypes.createRemindersPersonalTestsSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.createRemindersPersonalTestsFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },
};

export default actions;
