import feedbackApi from '@/api/feedback';
import {mutationTypes} from '@/store/modules/feedback/mutations';

export const actionsTypes = {
  getMethodologies: '[feedback] get methodologies',
  getMethodology: '[feedback] get methodology',
  createMethodology: '[feedback] create methodology',
  changeMethodology: '[feedback] change methodology',
  copyMethodology: '[feedback] copy methodology',
  exportMethodology: '[feedback] export methodology',
  importMethodology: '[feedback] import methodology',
  deleteMethodology: '[feedback] delete methodology',

  getFeedbacks: '[feedback] get feedbacks',
  getUserFeedbacks: '[feedback] get user feedbacks',
  getFeedback: '[feedback] get feedback',
  createFeedback: '[feedback] create feedback',
  changeFeedback: '[feedback] change feedback',
  copyFeedback: '[feedback] copy feedback',
  deleteFeedback: '[feedback] delete feedback',

  getStatistics: '[feedback] get statistics',
  getResults: '[feedback] get results',
  getCycles: '[feedback] get cycles',
};

const actions = {
  [actionsTypes.getMethodologies](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMethodologiesStart);
      feedbackApi
        .getMethodologies(companyId)
        .then((response) => {
          context.commit(mutationTypes.getMethodologiesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMethodologiesFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getMethodology](context, {companyId, methodologyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getMethodologyStart);
      feedbackApi
        .getMethodology(companyId, methodologyId)
        .then((response) => {
          context.commit(mutationTypes.getMethodologySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createMethodology](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createMethodologyStart);
      feedbackApi
        .createMethodology(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.createMethodologySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeMethodology](context, {companyId, methodologyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeMethodologyStart);
      feedbackApi
        .changeMethodology(companyId, methodologyId, data)
        .then((response) => {
          context.commit(mutationTypes.changeMethodologySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.copyMethodology](context, {companyId, methodologyId, toCompany, toCompanies}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.copyMethodologyStart);
      feedbackApi
        .copyMethodology(companyId, methodologyId, toCompany, toCompanies)
        .then((response) => {
          context.commit(mutationTypes.copyMethodologySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.copyMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.exportMethodology](context, {companyId, methodologyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.exportMethodologyStart);
      feedbackApi
        .exportMethodology(companyId, methodologyId)
        .then((response) => {
          context.commit(mutationTypes.exportMethodologySuccess);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.exportMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.importMethodology](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.importMethodologyStart);
      feedbackApi
        .importMethodology(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.importMethodologySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.importMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteMethodology](context, {companyId, methodologyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteMethodologyStart);
      feedbackApi
        .deleteMethodology(companyId, methodologyId)
        .then((response) => {
          context.commit(mutationTypes.deleteMethodologySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteMethodologyFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getFeedbacks](context, companyId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getFeedbacksStart);
      feedbackApi
        .getFeedbacks(companyId)
        .then((response) => {
          context.commit(mutationTypes.getFeedbacksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFeedbacksFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getFeedback](context, {companyId, feedbackId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getFeedbackStart);
      feedbackApi
        .getFeedback(companyId, feedbackId)
        .then((response) => {
          context.commit(mutationTypes.getFeedbackSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFeedbackFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createFeedback](context, {companyId, data, check}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createFeedbackStart);
      feedbackApi
        .createFeedback(companyId, data, check)
        .then((response) => {
          context.commit(mutationTypes.createFeedbackSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createFeedbackFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeFeedback](context, {companyId, feedbackId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeFeedbackStart);
      feedbackApi
        .changeFeedback(companyId, feedbackId, data)
        .then((response) => {
          context.commit(mutationTypes.changeFeedbackSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeFeedbackFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.copyFeedback](context, {companyId, feedbackId, toCompany, toCompanies}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.copyFeedbackStart);
      feedbackApi
        .copyFeedback(companyId, feedbackId, toCompany, toCompanies)
        .then((response) => {
          context.commit(mutationTypes.copyFeedbackSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.copyFeedbackFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteFeedback](context, {companyId, feedbackId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteFeedbackStart);
      feedbackApi
        .deleteFeedback(companyId, feedbackId)
        .then((response) => {
          context.commit(mutationTypes.deleteFeedbackSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteFeedbackFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getStatistics](context, {companyId, feedbackId, cycle, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getStatisticsStart);
      feedbackApi
        .getStatistics(companyId, feedbackId, {cycle, userId})
        .then((response) => {
          context.commit(mutationTypes.getStatisticsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getStatisticsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getUserFeedbacks](context, {companyId, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserFeedbacksStart);
      feedbackApi
        .getUserFeedbacks(companyId, userId)
        .then((response) => {
          context.commit(mutationTypes.getUserFeedbacksSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUserFeedbacksFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getResults](context, {companyId, feedbackId, cycle, teamId, userId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResultsStart);
      feedbackApi
        .getResults(companyId, feedbackId, cycle, teamId, userId)
        .then((response) => {
          context.commit(mutationTypes.getResultsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResultsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCycles](context, {companyId, feedbackId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResultsStart);
      feedbackApi
        .getCycles(companyId, feedbackId)
        .then((response) => {
          context.commit(mutationTypes.getResultsCyclesSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResultsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
