export default {
  loadingObjCount: {},
  filters: {
    cycle: undefined,
    team: undefined,
    last_name: undefined,
  },
  filterInited: false,
  teamGroups: {},

  statistic: {
    dates: undefined,
    employee: undefined,
    joined: undefined,
    percent: undefined,
    proportion: undefined,
    dynamic: undefined,
  },

  emotion: {
    anxiety: undefined,
    average: undefined,
  },

  enps: undefined,

  participation: {
    involvement: undefined,
    involvementDynamic: undefined,
    contentment: undefined,
    contentmentDynamic: undefined,
    summary: undefined,
    summaryTeam: undefined,
  },

  participationFactors: {
    involvement: undefined,
    successConditions: undefined,
    developmentOpportunities: undefined,
    rewardRecognition: undefined,
    lead: undefined,
    team: undefined,
    authority: undefined,
    consist: undefined,
    clientFocus: undefined,
    respect: undefined,
    innovation: undefined,
    collaboration: undefined,
  },

  burnout: {
    general: undefined,
    generalDynamic: undefined,
    generalByTeam: undefined,
    emotion: undefined,
    emotionDynamic: undefined,
    emotionFactors: undefined,
    depersonalization: undefined,
    depersonalizationDynamic: undefined,
    depersonalizationFactors: undefined,
    efficiency: undefined,
    efficiencyDynamic: undefined,
    efficiencyFactors: undefined,
    overCyclesDynamic: undefined,
  },

  burnoutByEmployee: {
    stages: undefined,
    general: undefined,
    emotion: undefined,
    depersonalization: undefined,
    efficiency: undefined,
  },
};
