// eslint-disable-next-line no-undef
RedactorX.lang["en"] = {
  accessibility: {
    "help-label": "Rich text editor"
  },
  placeholders: {
    figcaption: "Type caption (optional)"
  },
  popup: {
    back: "Back",
    link: "Link",
    add: "Add",
    image: "Image",
    "add-image": "Add Image"
  },
  shortcuts: {
    "meta-a": "Select all",
    "meta-z": "Undo",
    "meta-shift-z": "Redo",
    "meta-shift-m": "Remove inline format",
    "meta-b": "Bold",
    "meta-i": "Italic",
    "meta-u": "Underline",
    "meta-h": "Superscript",
    "meta-l": "Subscript",
    "meta-k": "Link",
    "meta-alt-0": "Normal text",
    "meta-alt-1": "Heading 1",
    "meta-alt-2": "Heading 2",
    "meta-alt-3": "Heading 3",
    "meta-alt-4": "Heading 4",
    "meta-alt-5": "Heading 5",
    "meta-alt-6": "Heading 6",
    "meta-shift-7": "Ordered List",
    "meta-shift-8": "Unordered List",
    "meta-indent": "Indent",
    "meta-outdent": "Outdent",
    "meta-shift-backspace": "Delete",
    "meta-shift-o": "Add",
    "meta-shift-d": "Duplicate",
    "meta-shift-up": "Move up",
    "meta-shift-down": "Move down"
  },
  buttons: {
    add: "Add",
    html: "HTML",
    format: "Format",
    bold: "Bold",
    italic: "Italic",
    deleted: "Deleted",
    link: "Link",
    list: "List",
    image: "Image",
    indent: "Indent",
    outdent: "Outdent",
    embed: "Embed",
    table: "Table",
    insert: "Insert",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete",
    duplicate: "Duplicate",
    shortcut: "Shortcuts",
    underline: "Underline",
    undo: "Undo",
    redo: "Redo",
    code: "Code",
    mark: "Mark",
    subscript: "Subscript",
    superscript: "Superscript",
    kbd: "Shortcut",
    "image-settings": "Image settings",
    "column-settings": "Column settings"
  },
  blocks: {
    text: "Text",
    paragraph: "Paragraph",
    image: "Image",
    embed: "Embed",
    line: "Line",
    table: "Table",
    quote: "Quote",
    pre: "Code",
    address: "Address"
  },
  format: {
    p: "Normal Text",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    address: "Address",
    ul: "Unordered List",
    ol: "Ordered List",
    dl: "Definition List"
  },
  embed: {
    embed: "Embed",
    caption: "Caption",
    description: "Paste any embed/html code or enter the url (vimeo or youtube video only)",
    "responsive-video": "Responsive video"
  },
  image: {
    or: "or",
    "alt-text": "Alt Text",
    link: "Link",
    width: "Width",
    caption: "Caption",
    "link-in-new-tab": "Open link in new tab",
    "url-placeholder": "Paste url of image...",
    "upload-new-placeholder": "Drag to upload a new image<br>or click to select"
  },
  link: {
    link: "Link",
    "edit-link": "Edit Link",
    unlink: "Unlink",
    "link-in-new-tab": "Open link in new tab",
    text: "Text",
    url: "URL"
  },
  table: {
    width: "Width",
    nowrap: "Nowrap",
    column: "Column",
    "add-head": "Add head",
    "remove-head": "Remove head",
    "add-row-below": "Add row below",
    "add-row-above": "Add row above",
    "remove-row": "Remove row",
    "add-column-after": "Add column after",
    "add-column-before": "Add column before",
    "remove-column": "Remove column",
    "delete-table": "Delete table"
  }
};
