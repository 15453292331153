<template>
  <div class="d-flex flex-column w-100">
    <!--    <app-field-title-with-tooltip-->
    <!--      :field-title="fieldTitle"-->
    <!--      :field-title-tooltip="fieldTitleTooltip"-->
    <!--    />-->

    <!-- eslint-disable vue/no-v-html -->
    <span v-if="disabled" v-html="value"></span>

    <app-redactor-x
      v-else
      :id="id"
      class="w-100"
      :value="value"
      :invalid="invalid"
      :placeholder="placeholder"
      :config="redactorConfig"
      :field-title="fieldTitle"
      @input="$emit('input', $event)"
    >
      <template v-if="shouldShowLabelControls" #label-controls>
        <slot name="label-controls"></slot>
      </template>

      <template v-if="shouldShowHintSlot" #hint>
        <slot name="hint"></slot>
      </template>
    </app-redactor-x>

    <app-item-hint v-if="message" :title="message" />
  </div>
</template>

<script>
import AppRedactorX from './redactor-x/RedactorXContainer';
import {required} from '@/helpers/validators';
import {getNormalizedSlot} from '@/helpers/utils';

export default {
  name: 'AppRichTextArea',

  components: {
    AppRedactorX,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    fieldTitle: {
      type: String,
      default: '',
    },

    fieldTitleTooltip: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
      default: false,
    },

    allowImages: {
      type: Boolean,
      default: false,
    },

    allowVideo: {
      type: Boolean,
      default: false,
    },

    allowFiles: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    handleUserSearch: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },

  computed: {
    rules() {
      return this.required ? [required] : [];
    },

    shouldShowLabelControls() {
      return getNormalizedSlot('label-controls', this);
    },

    shouldShowHintSlot() {
      return getNormalizedSlot('hint', this);
    },

    redactorConfig() {
      return {
        imageUpload: this.allowImages ? '/api/v1/utils/file' : undefined,
        fileUpload: this.allowFiles ? '/api/v1/utils/file' : undefined,
        imageResizable: this.allowImages ? true : undefined,
        handle: this.handleUserSearch ? this.handleUserSearch : undefined,
        handleStart: this.handleUserSearch ? 1 : undefined,
        minHeight: '150px',
        plugins: [
          'alignment',
          'removeformat',
          'inlineformat',
          'limiter',
          'table',
          this.allowVideo && 'video',
          this.handleUserSearch && 'handle',
        ].filter(Boolean),
      };
    },
  },
};
</script>

<style>
.color-white {
  color: white !important;
}
</style>
