export default {
  // Открыта регистрация
  viewRegisterForm: {
    yandex: 'registrationViewed',
  },
  // Регистрация завершена
  registrationCompleted: {
    yandex: 'registrationCompleted',
    yandex_aux: 'reg_end',
    facebook: 'registrationCompleted',
  },
  // Авторизация
  login: {
    yandex: 'login',
  },
  // Запущен тест самооценки
  evalStarted: {
    yandex: 'evalStarted',
  },
  // Завершен тест самооценки
  evalFinished: {
    yandex: 'evalFinished',
  },
  // Запущен внешний тест
  externalTestStarted: {
    yandex: 'externalTestStarted',
  },
  // Выгрузка личного отчета Hard
  persReportViewedHard: {
    yandex: 'persReportViewedHard',
  },
  // Выгрузка личного отчета Soft
  persReportViewedSoft: {
    yandex: 'persReportViewedSoft',
  },
  // Выгрузка личного отчета Motivation
  persReportViewedMotivation: {
    yandex: 'persReportViewedMotivation',
  },
  // Создание компании
  companyCreated: {
    yandex: 'companyCreated',
  },
  // Создание команды
  teamCreated: {
    yandex: 'teamCreated',
  },
  // Назначение тестирования
  testingAssigned: {
    yandex: 'testingAssigned',
  },
  // Формирование командного отчета Soft
  teamReportSoft: {
    yandex: 'teamReportSoft',
  },
  // Формирование командного отчета Hard
  teamReportHard: {
    yandex: 'teamReportHard',
  },
  // Формирование командного отчета Направление развития карьеры и мотивация
  teamReportmotivation: {
    yandex: 'teamReportmotivation',
  },
  // Выгрузка личного отчета в команде Hard
  teamPersReportHard: {
    yandex: 'teamPersReportHard',
  },
  // Выгрузка личного отчета в команде Soft
  teamPersReportSoft: {
    yandex: 'teamPersReportSoft',
  },
  // Выгрузка личного отчета в команде Motivation
  teamPersReportMotivation: {
    yandex: 'teamPersReportMotivation',
  },
  // Выгрузка личного отчета Роли в команде
  persReportViewedTeam_roles: {
    yandex: 'persReportViewedTeam_roles',
  },
  // Выгрузка командного отчета Роли в команде
  teamReportViewedTeam_roles: {
    yandex: 'teamReportViewedTeam_roles',
  },
  // Выгрузка личного отчета Роли в команде по участнику команды
  team_memberReportViewedTeam_roles: {
    yandex: 'team_memberReportViewedTeam_roles',
  },
  // Выгрузка личного отчета 360
  persReportViewedtest360: {
    yandex: 'persReportViewedtest360',
  },
  // Выгрузка личного отчета 360 по участнику команды
  team_memberReportViewedtest360: {
    yandex: 'teamReportViewedtest360',
  },
  // Кнопка "назначить тест 360"
  test360AssignmentButtonPressed: {
    yandex: 'test360Assignment',
  },
  // Тест 360 стартовал
  test360Started: {
    yandex: 'test360Start',
  },
  // Завершение демотеста
  demoTestCompleted: {
    yandex: 'demo_end',
  },
  // Дорегистрация после демотеста
  demoTestRegistration: {
    yandex: 'demo_reg',
    yandex_aux: 'reg_end',
    facebook: 'registrationCompleted',
  },
  devMapRequest: {
    yandex: 'map_request',
  },
  devMapClickedInReport: {
    yandex: 'map_report',
  },
  devMapClickedInSkills: {
    yandex: 'map_skills',
  },
};
