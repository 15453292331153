import analyticApi from '@/api/analytic';
import companyApi from '@/api/company';
import {mutationTypes} from '@/store/modules/analytic/mutations';
import {gettersTypes} from '@/store/modules/analytic/getters';

export const actionsTypes = {
  getStatistic: '[analytic] get statistic',
  getEmotion: '[analytic] get emotion',
  getEnps: '[analytic] get enps',
  getParticipation: '[analytic] get participation',
  getParticipationFactors: '[analytic] get participation factors',
  getBurnout: '[analytic] get burnout',
  getBurnoutByEmployee: '[analytic] get burnout by employee',
  getTeamGroups: '[analytic] get team groups',
};

const actions = {
  [actionsTypes.getStatistic](context, {companyId, researchmentId, reportType, filters}) {
    const params = filters ?? {
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getStatistic(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeStatistic, {reportType, payload: response.data});
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});
          reject(result);
        });
    });
  },

  [actionsTypes.getEmotion](context, {companyId, researchmentId, reportType, filters}) {
    const params = filters ?? {
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getEmotion(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeEmotion, {reportType, payload: response.data});
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getEnps](context, {companyId, researchmentId, filters}) {
    const params = filters ?? {
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [actionsTypes.getEnps];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getNPS(companyId, researchmentId, params)
        .then((response) => {
          context.commit(mutationTypes.storeEnps, response.data);
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getParticipation](context, {companyId, researchmentId, reportType, filters}) {
    const params = filters ?? {
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getParticipation(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeParticipation, {reportType, payload: response.data});
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getParticipationFactors](
    context,
    {companyId, researchmentId, reportType, filters}
  ) {
    const params = filters ?? {
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getParticipationFactors(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeParticipationFactors, {
            reportType,
            payload: response.data,
          });
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getBurnout](context, {companyId, researchmentId, reportType, filters}) {
    const params = filters ?? {
      ...context.state.filters,
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getBurnout(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeBurnout, {reportType, payload: response.data});
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getBurnoutByEmployee](context, {companyId, researchmentId, reportType, filters}) {
    const params = filters ?? {
      ...context.state.filters,
      team: context.getters[gettersTypes.filterTeamIds],
      cycle: context.getters[gettersTypes.filterCycle],
    };
    const loaderObj = [reportType];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      analyticApi
        .getBurnoutByEmployee(companyId, researchmentId, reportType, params)
        .then((response) => {
          context.commit(mutationTypes.storeBurnoutByEmployee, {
            reportType,
            payload: response.data,
          });
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});

          reject(result);
        });
    });
  },

  [actionsTypes.getTeamGroups](context, {companyId}) {
    const loaderObj = [actionsTypes.getTeamGroups];
    context.commit(mutationTypes.setLoadingObject, {loaderObj, data: true});

    return new Promise((resolve, reject) => {
      companyApi
        .getTeamGroups(companyId)
        .then((response) => {
          context.commit(mutationTypes.storeTeamGroups, response.data);
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.setLoadingObject, {loaderObj, data: false});
          reject(result);
        });
    });
  },
};

export default actions;
