<i18n>
{
  "ru": {
    "errorTitle": "Ой,что-то сломалось",
    "errorMessage": "К сожалению, запрашиваемая страница не найдена. Возможно, вы перешли по ссылке, в которой была допущена ошибка или страница была удалена"
  },
  "en": {
    "errorTitle": "Oops,something's broken",
    "errorMessage": "Unfortunately, the requested page was not found. You may have followed a link that contained an error or the page may have been deleted"
  }
}
</i18n>
<template>
  <div class="page-not-found d-flex align-center">
    <div class="bg-decoration" />
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="6" xl="4">
          <div :class="{'text-center': $vuetify.breakpoint.mobile}">
            <app-title size="super-large" classes="mb-5 mb-md-10">404</app-title>
            <app-title size="huge">{{ $t('errorTitle') }}</app-title>
            <p class="mt-4 mb-8">{{ $t('errorMessage') }}</p>
            <v-btn
              depressed
              color="primary"
              class="primary-contrast--text"
              @click="$router.back()"
              >{{ $t('buttons.goBack') }}</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="4" class="position-relative">
          <figure class="image-decoration">
            <img
              src="@/assets/img/bee.png"
              alt="bee"
              :style="{'max-width': !$vuetify.breakpoint.xlOnly ? '100%' : '150%'}"
            />
          </figure>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AppPageNotFound',
};
</script>

<style lang="scss" scoped>
.page-not-found {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::v-deep .bg-decoration {
    position: fixed;
    top: 0;
    right: -10%;
    width: 50%;
    height: 100%;
    background-image: url('@/assets/img/hives.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;

    .is-mobile & {
      width: 100%;
      right: 0;
    }
  }
  &::v-deep .image-decoration {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    height: 100%;
    transform: rotate(0.98turn) translate3d(45%, 10%, 0);
    animation: swing 4s ease-in-out infinite;

    .is-mobile & {
      width: 50%;
      top: 100%;
      right: 55%;
      transform: rotate(1.02turn) translate3d(0, 0, 0);
      animation: none;
    }
  }
}
@keyframes swing {
  0% {
    transform: rotate(0.98turn) translate3d(45%, 10%, 0);
  }
  50% {
    transform: rotate(0.98turn) translate3d(45%, 0, 0);
  }
  100% {
    transform: rotate(0.98turn) translate3d(45%, 10%, 0);
  }
}
</style>
