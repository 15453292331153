export default {
  isLoading: false,
  specializations: null,
  reallyAllSpecializations: null,
  specialization: null,
  addresses: null,
  organizations: null,
  services: null,
  skills: null,
  testingIndicators: null,
  materialTypes: null,
  targetTypes: null,
  scales: null,
  errors: null,
};
