import Vue from 'vue';

export const mutationTypes = {
  uploadFileStart: '[utils] upload file start',
  uploadFileSuccess: '[utils] upload file success',
  uploadFileFailure: '[utils] upload file failure',

  getBoundTagsStart: '[utils] get Bound Tags start',
  getBoundTagsSuccess: '[utils] get Bound Tags Success',
  getBoundTagsFailure: '[utils] get Bound Tags Failure',

  getTagsStart: '[utils] get Tags start',
  getTagsSuccess: '[utils] get Tags Success',
  getTagsFailure: '[utils] get Tags Failure',

  bindTagsStart: '[utils] bind Tags start',
  bindTagsSuccess: '[utils] bind Tags Success',
  bindTagsFailure: '[utils] bind Tags Failure',
};

const mutations = {
  [mutationTypes.uploadFileStart](state) {
    state.isFileUploading = true;
  },
  [mutationTypes.uploadFileSuccess](state) {
    state.isFileUploading = false;
  },
  [mutationTypes.uploadFileFailure](state) {
    state.isFileUploading = false;
  },

  [mutationTypes.getBoundTagsStart](state) {
    state.isTagsLoading = true;
  },
  [mutationTypes.getBoundTagsSuccess](state, {code, payload}) {
    state.isTagsLoading = false;
    Vue.set(state.boundTags, code, payload);
  },
  [mutationTypes.getBoundTagsFailure](state) {
    state.isTagsLoading = false;
  },

  [mutationTypes.getTagsStart](state) {
    state.isTagsLoading = true;
    state.tags = [];
  },
  [mutationTypes.getTagsSuccess](state, payload) {
    state.isTagsLoading = false;
    state.tags = payload;
  },
  [mutationTypes.getTagsFailure](state) {
    state.isTagsLoading = false;
  },

  [mutationTypes.bindTagsStart](state) {
    state.isTagsLoading = true;
  },
  [mutationTypes.bindTagsSuccess](state) {
    state.isTagsLoading = false;
  },
  [mutationTypes.bindTagsFailure](state) {
    state.isTagsLoading = false;
  },
};

export default mutations;
