import announcementsApi from '@/api/announcements';
import {mutationTypes} from '@/store/modules/announcements/mutations';

export const actionsTypes = {
  getAnnouncements: '[announcements] get announcements',
  getAnnouncementsAll: '[announcements] get announcements all',

  createAnnouncement: '[announcements] create announcement',
  deleteAnnouncement: '[announcements] delete announcement',
};

const actions = {
  [actionsTypes.getAnnouncements](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAnnouncementsStart);
      announcementsApi
        .getAnnouncements()
        .then((response) => {
          context.commit(mutationTypes.getAnnouncementsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAnnouncementsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getAnnouncementsAll](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAnnouncementsAllStart);
      announcementsApi
        .getAnnouncementsAll()
        .then((response) => {
          context.commit(mutationTypes.getAnnouncementsAllSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAnnouncementsAllFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.createAnnouncement](context, data) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createAnnouncementStart);
      announcementsApi
        .createAnnouncement(data)
        .then((response) => {
          context.commit(mutationTypes.createAnnouncementSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createAnnouncementFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.deleteAnnouncement](context, announcementId) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteAnnouncementStart);
      announcementsApi
        .deleteAnnouncement(announcementId)
        .then((response) => {
          context.commit(mutationTypes.deleteAnnouncementSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteAnnouncementFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
