<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <div
    class="app-main-container"
    :style="{'background-color': isPageNotFound ? colors.info : 'transparent'}"
  >
    <app-sidebar v-if="isLoggedIn" />
    <app-header v-if="$vuetify.breakpoint.mobile && isLoggedIn" />
    <v-main>
      <template v-if="localAnnouncements.length">
        <v-banner
          v-for="(announcement, announcementIndex) in localAnnouncements"
          :key="announcement.id"
          sticky
          tile
          elevation="0"
          color="primary"
          class="primary-contrast--text align-center"
          :class="{'mb-1': announcementIndex + 1 < localAnnouncements.length}"
        >
          <span>{{ announcement.description }}</span>
          <template #actions="{dismiss}">
            <v-btn
              fab
              icon
              small
              color="primary-contrast"
              class="align-self-center"
              @click="dismiss"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-banner>
      </template>
      <v-snackbar v-model="v.alert.visible" @input="closeAlert">
        <p v-for="(message, index) in v.alert.messages" :key="index">{{ message }}</p>
        <template #action="{attrs}">
          <v-btn color="blue" icon v-bind="attrs" @click="v.alert.visible = false">
            <v-icon v-text="'mdi-close'"></v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <app-footer v-if="!isPageNotFound"></app-footer>
    </v-main>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {actionsTypes as directoryActionsTypes} from '@/store/modules/directory/actions';
import {actionsTypes as userActionsTypes} from '@/store/modules/user/actions';
import {actionsTypes as companyActionsTypes} from '@/store/modules/company/actions';
import {actionsTypes as announcementsActionsTypes} from '@/store/modules/announcements/actions';

import is from 'is_js';
import _ from 'lodash';
import variables from '@/helpers/variables';
import {errorAlert} from '@/helpers/utils';
import {themeMixin} from '@/mixins/theme';

const AppHeader = () => import('@/components/layout/HeaderContainer');
const AppSidebar = () => import('@/components/layout/sidebar/SidebarContainer');
import AppFooter from '@/components/layout/Footer';

export default {
  name: 'MainView',
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
  },
  mixins: [themeMixin],
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      authUser: (state) => state.auth.currentUser,
      announcements: (state) => state.announcements.announcements,
      userNotifications: (state) => state.user.notifications,
      userEmployers: (state) => state.user.employers,
      userCompanies: (state) => state.user.companies,
      reallyAllSpecializations: (state) => state.directory.reallyAllSpecializations,
      defaultSpecializations: (state) => state.directory.specializations,
    }),
    isPageNotFound() {
      return this.$route.name === this.$routeNames.main.notFound;
    },
    localAnnouncements() {
      return _.cloneDeep(this.announcements || []);
    },
  },
  created() {
    this.setCustomFont();
    this.setCustomTheme();
    this.addClassActiveTheme();

    if (!this.isLoggedIn) return;

    if (is.null(this.announcements)) this.getAnnouncements();
    if (is.null(this.userNotifications)) this.getUserNotifications();
    if (is.null(this.userEmployers)) this.getUserEmployers();
    if (is.null(this.userCompanies)) this.getUserCompanies();
    if (is.null(this.defaultSpecializations)) this.getDefaultSpecializations();
    if (is.null(this.reallyAllSpecializations)) this.getReallyAllSpecializations();
  },
  methods: {
    getAnnouncements() {
      this.$store.dispatch(announcementsActionsTypes.getAnnouncements);
    },
    getUserNotifications() {
      this.$store.dispatch(userActionsTypes.getNotifications);
    },
    getUserEmployers() {
      this.$store.dispatch(userActionsTypes.getEmployers, {
        id: this.authUser.user_id,
      });
    },
    getUserCompanies() {
      this.$store.dispatch(userActionsTypes.getCompanies, {
        id: this.authUser.user_id,
      });
    },
    getDefaultSpecializations() {
      this.$store.dispatch(directoryActionsTypes.getSpecializations);
    },
    getReallyAllSpecializations() {
      this.$store.dispatch(directoryActionsTypes.getReallyAllSpecializations);
    },
    getSpecializationsCurrentCompany() {
      if (!this.authUser.company_id) return false;

      this.$store
        .dispatch(companyActionsTypes.getCurrentSpecializations, this.authUser.company_id)
        .catch(({response}) => {
          if (response.status === 404) {
            errorAlert(
              this.$t('errorMessages.specializations').replace(
                '{company_id}',
                this.authUser.company_id
              )
            );
          } else errorAlert(`${response.status} ${response.data.message}`);
        });
    },
    closeAlert() {
      this.v.alert.messages = [];
    },
  },
};
</script>

<style lang="scss">
.app-main-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
}
</style>
