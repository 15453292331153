var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{key:_vm.notMatch,attrs:{"optional":"","background-color":"info","slider-color":"blue","color":"blue","active-class":"blue--text","show-arrows":""}},[_vm._l((_vm.data),function(tab){return [(tab.key)?_c('v-tab',{key:tab.key,attrs:{"disabled":tab.isDisabled,"to":Object.assign(
          {},
          {name: tab.key},
          tab.params ? {params: tab.params} : {},
          tab.query ? {query: tab.query} : {}
        ),"exact-path":""}},[_c('span',[_vm._v(_vm._s(tab.title))]),(tab.count)?_c('v-badge',{attrs:{"inline":"","color":"warning","content":tab.count}}):_vm._e()],1):(tab.submenu && tab.submenu.length)?_c('v-menu',{key:tab.key,attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"to":{name: _vm.getRouteName(tab.submenu) || tab.submenu[0].key},"exact-path":""}},'v-tab',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.getTitleTab(tab)))]),(tab.count)?_c('v-badge',{staticClass:"ms-1",attrs:{"inline":"","color":"warning","content":tab.count}}):_vm._e(),_c('v-icon',{attrs:{"right":""},domProps:{"textContent":_vm._s('mdi-chevron-down')}})],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((tab.submenu),function(tabSub){return _c('v-list-item',{key:tabSub.key,attrs:{"to":{name: tabSub.key},"disabled":tabSub.isDisabled,"color":"blue","exact-path":""}},[_c('v-list-item-title',[_vm._v(_vm._s(tabSub.title))]),(tabSub.count)?_c('v-list-item-action',[_c('v-badge',{attrs:{"inline":"","color":"warning","content":tabSub.count}})],1):_vm._e()],1)}),1)],1):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }