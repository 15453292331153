import axios from '@/api/axios';

const getLicenses = () => axios.get('/license');

const assignLicenses = (data) => axios.post('/license/assign', data);

export default {
  getLicenses,
  assignLicenses,
};
