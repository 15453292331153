<template>
  <v-tooltip
    top
    content-class="bbd-ec__tooltip bbd-ec__tooltip-decoration-bottom"
    color="secondary"
  >
    <template #activator="{on}">
      <slot name="content" :on="on">
        <v-btn
          height="16"
          width="16"
          color="white"
          icon
          style="margin-bottom: 2px"
          @click.prevent
          v-on="tooltip && tooltip !== '-' ? on : undefined"
        >
          <v-icon :color="iconColor" size="16">{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>

    <span class="d-block bbd-ec__medium-weight-text text-white" style="max-width: 200px">{{
      tooltip
    }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppFieldHint',

  props: {
    tooltip: {
      type: String,
      default: undefined,
    },

    icon: {
      type: String,
      default: 'mdi-information-outline',
    },

    iconColor: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style></style>
