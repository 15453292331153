import {errorAlert} from '@/helpers/utils';

export const actionsTypes = {
  processBroadcast: '[socket] process broadcast',
};

const actions = {
  [actionsTypes.processBroadcast](context, {message}) {
    try {
      if (message) errorAlert(message);
    } catch (e) {
      console.error(`Failed to display broadcast. ${e}`);
    }
  },
};

export default actions;
