export const mutationTypes = {
  getMethodologiesStart: '[feedback] get methodologies start',
  getMethodologiesSuccess: '[feedback] get methodologies success',
  getMethodologiesFailure: '[feedback] get methodologies failure',

  getMethodologyStart: '[feedback] get methodology start',
  getMethodologySuccess: '[feedback] get methodology success',
  getMethodologyFailure: '[feedback] get methodology failure',

  createMethodologyStart: '[feedback] create methodology start',
  createMethodologySuccess: '[feedback] create methodology success',
  createMethodologyFailure: '[feedback] create methodology failure',

  changeMethodologyStart: '[feedback] change methodology start',
  changeMethodologySuccess: '[feedback] change methodology success',
  changeMethodologyFailure: '[feedback] change methodology failure',

  copyMethodologyStart: '[feedback] copy methodology start',
  copyMethodologySuccess: '[feedback] copy methodology success',
  copyMethodologyFailure: '[feedback] copy methodology failure',

  exportMethodologyStart: '[feedback] export methodology start',
  exportMethodologySuccess: '[feedback] export methodology success',
  exportMethodologyFailure: '[feedback] export methodology failure',

  importMethodologyStart: '[feedback] import methodology start',
  importMethodologySuccess: '[feedback] import methodology success',
  importMethodologyFailure: '[feedback] import methodology failure',

  deleteMethodologyStart: '[feedback] delete methodology start',
  deleteMethodologySuccess: '[feedback] delete methodology success',
  deleteMethodologyFailure: '[feedback] delete methodology failure',

  getFeedbacksStart: '[feedback] get feedbacks start',
  getFeedbacksSuccess: '[feedback] get feedbacks success',
  getFeedbacksFailure: '[feedback] get feedbacks failure',

  getUserFeedbacksStart: '[feedback] get user feedbacks start',
  getUserFeedbacksSuccess: '[feedback] get user feedbacks success',
  getUserFeedbacksFailure: '[feedback] get user feedbacks failure',

  getFeedbackStart: '[feedback] get feedback start',
  getFeedbackSuccess: '[feedback] get feedback success',
  getFeedbackFailure: '[feedback] get feedback failure',

  createFeedbackStart: '[feedback] create feedback start',
  createFeedbackSuccess: '[feedback] create feedback success',
  createFeedbackFailure: '[feedback] create feedback failure',

  changeFeedbackStart: '[feedback] change feedback start',
  changeFeedbackSuccess: '[feedback] change feedback success',
  changeFeedbackFailure: '[feedback] change feedback failure',

  copyFeedbackStart: '[feedback] copy feedback start',
  copyFeedbackSuccess: '[feedback] copy feedback success',
  copyFeedbackFailure: '[feedback] copy feedback failure',

  deleteFeedbackStart: '[feedback] delete feedback start',
  deleteFeedbackSuccess: '[feedback] delete feedback success',
  deleteFeedbackFailure: '[feedback] delete feedback failure',

  getStatisticsStart: '[feedback] get statistics start',
  getStatisticsSuccess: '[feedback] get statistics success',
  getStatisticsFailure: '[feedback] get statistics failure',

  getResultsStart: '[feedback] get results start',
  getResultsSuccess: '[feedback] get results success',
  getResultsCyclesSuccess: '[feedback] get results cycles success',
  getResultsFailure: '[feedback] get results failure',
};

const mutations = {
  [mutationTypes.getMethodologiesStart](state) {
    state.isLoading = true;
    state.methodologies = null;
  },
  [mutationTypes.getMethodologiesSuccess](state, payload) {
    state.isLoading = false;
    state.methodologies = payload;
  },
  [mutationTypes.getMethodologiesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMethodologyStart](state) {
    state.isLoading = true;
    state.methodology = null;
  },
  [mutationTypes.getMethodologySuccess](state, payload) {
    state.isLoading = false;
    state.methodology = payload;
  },
  [mutationTypes.getMethodologyFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.copyMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.copyMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.copyMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.exportMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.importMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.importMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.importMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteMethodologyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteMethodologySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteMethodologyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getFeedbacksStart](state) {
    state.isLoading = true;
    state.feedbacks = null;
  },
  [mutationTypes.getFeedbacksSuccess](state, payload) {
    state.isLoading = false;
    state.feedbacks = payload;
  },
  [mutationTypes.getFeedbacksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getUserFeedbacksStart](state) {
    state.isLoading = true;
    state.userFeedbacks = null;
  },
  [mutationTypes.getUserFeedbacksSuccess](state, payload) {
    state.isLoading = false;
    state.userFeedbacks = payload;
  },
  [mutationTypes.getUserFeedbacksFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getFeedbackStart](state) {
    state.isLoading = true;
    state.feedback = null;
  },
  [mutationTypes.getFeedbackSuccess](state, payload) {
    state.isLoading = false;
    state.feedback = payload;
  },
  [mutationTypes.getFeedbackFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createFeedbackStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createFeedbackSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createFeedbackFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeFeedbackStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeFeedbackSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeFeedbackFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.copyFeedbackStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.copyFeedbackSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.copyFeedbackFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteFeedbackStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteFeedbackSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteFeedbackFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getStatisticsStart](state) {
    state.isLoading = true;
    state.statistics = null;
  },
  [mutationTypes.getStatisticsSuccess](state, payload) {
    state.isLoading = false;
    state.statistics = payload;
  },
  [mutationTypes.getStatisticsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getResultsStart](state) {
    state.isLoading = true;
    state.results = null;
  },
  [mutationTypes.getResultsSuccess](state, payload) {
    state.isLoading = false;
    state.results = payload;
  },
  [mutationTypes.getResultsCyclesSuccess](state, payload) {
    state.isLoading = false;
    state.cycles = payload;
  },
  [mutationTypes.getResultsFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
