export const mutationTypes = {
  getUsersStart: '[user] get users start',
  getUsersSuccess: '[user] get users success',
  getUsersFailure: '[user] get users failure',

  getCurrentUserStart: '[user] get current user start',
  getCurrentUserSuccess: '[user] get current user success',
  getCurrentUserFailure: '[user] get current user failure',

  getMetadataUserStart: '[user] get metadata start',
  getMetadataUserSuccess: '[user] get metadata success',
  getMetadataUserFailure: '[user] get metadata failure',

  getPlatformRightsStart: '[user] get platform rights start',
  getPlatformRightsSuccess: '[user] get platform rights success',
  getPlatformRightsFailure: '[user] get platform rights failure',

  getCurrentAccessRightsStart: '[user] get current access rights start',
  getCurrentAccessRightsSuccess: '[user] get current access rights success',
  getCurrentAccessRightsFailure: '[user] get current access rights failure',

  getAccessRightsStart: '[user] get access rights start',
  getAccessRightsSuccess: '[user] get access rights success',
  getAccessRightsFailure: '[user] get access rights failure',

  getSpecsWithGradesStart: '[user] get specializations with grades start',
  getSpecsWithGradesSuccess: '[user] get specializations with grades success',
  getSpecsWithGradesFailure: '[user] get specializations with grades failure',

  changeMetadataUserStart: '[user] change metadata start',
  changeMetadataUserSuccess: '[user] change metadata success',
  changeMetadataUserFailure: '[user] change metadata failure',

  changeAvatarStart: '[user] change avatar start',
  changeAvatarSuccess: '[user] change avatar success',
  changeAvatarFailure: '[user] change avatar failure',

  changeEmailStart: '[user] change email start',
  changeEmailSuccess: '[user] change email success',
  changeEmailFailure: '[user] change email failure',
  changeEmailFailureCode: '[user] change email failure code',

  changeSpecializationsStart: '[user] change specializations start',
  changeSpecializationsSuccess: '[user] change specializations success',
  changeSpecializationsFailure: '[user] change specializations failure',

  changeHRInfoStart: '[user] change HR information start',
  changeHRInfoSuccess: '[user] change HR information success',
  changeHRInfoFailure: '[user] change HR information failure',

  createHRAttachmentStart: '[user] create HR attachment start',
  createHRAttachmentSuccess: '[user] create HR attachment success',
  createHRAttachmentFailure: '[user] create HR attachment failure',

  deleteHRAttachmentStart: '[user] delete HR attachment start',
  deleteHRAttachmentSuccess: '[user] delete HR attachment success',
  deleteHRAttachmentFailure: '[user] delete HR attachment failure',

  getEmployersStart: '[user] get employers start',
  getEmployersSuccess: '[user] get employers success',
  getEmployersFailure: '[user] get employers failure',

  getCompaniesStart: '[user] get companies start',
  getCompaniesSuccess: '[user] get companies success',
  getCompaniesFailure: '[user] get companies failure',

  createCompanyStart: '[user] create company start',
  createCompanySuccess: '[user] create company success',
  createCompanyFailure: '[user] create company failure',

  createCompanyLogoStart: '[user] create company logo start',
  createCompanyLogoSuccess: '[user] create company logo success',
  createCompanyLogoFailure: '[user] create company logo failure',

  getPortfolioStart: '[user] get portfolio start',
  getPortfolioSuccess: '[user] get portfolio success',
  getPortfolioFailure: '[user] get portfolio failure',

  createPortfolioStart: '[user] create portfolio start',
  createPortfolioSuccess: '[user] create portfolio success',
  createPortfolioFailure: '[user] create portfolio failure',

  deletePortfolioStart: '[user] delete portfolio start',
  deletePortfolioSuccess: '[user] delete portfolio success',
  deletePortfolioFailure: '[user] delete portfolio failure',

  getWorkExpStart: '[user] get work experience start',
  getWorkExpSuccess: '[user] get work experience success',
  getWorkExpFailure: '[user] get work experience failure',

  createWorkExpStart: '[user] create work experience start',
  createWorkExpSuccess: '[user] create work experience success',
  createWorkExpFailure: '[user] create work experience failure',

  changeWorkExpStart: '[user] change work experience start',
  changeWorkExpSuccess: '[user] change work experience success',
  changeWorkExpFailure: '[user] change work experience failure',

  deleteWorkExpStart: '[user] delete work experience start',
  deleteWorkExpSuccess: '[user] delete work experience success',
  deleteWorkExpFailure: '[user] delete work experience failure',

  getEducationStart: '[user] get education start',
  getEducationSuccess: '[user] get education success',
  getEducationFailure: '[user] get education failure',

  changeEducationStart: '[user] change education start',
  changeEducationSuccess: '[user] change education success',
  changeEducationFailure: '[user] change education failure',

  deleteEducationStart: '[user] delete education start',
  deleteEducationSuccess: '[user] delete education success',
  deleteEducationFailure: '[user] delete education failure',

  getReportsStart: '[user] get reports start',
  getReportsSuccess: '[user] get reports success',
  getReportsFailure: '[user] get reports failure',

  getTests360Start: '[user] get tests 360 start',
  getTests360Success: '[user] get tests 360 success',
  getTests360Failure: '[user] get tests 360 failure',

  getTests360ForApprovalStart: '[user] get tests 360 for approval start',
  getTests360ForApprovalSuccess: '[user] get tests 360 for approval success',
  getTests360ForApprovalFailure: '[user] get tests 360 for approval failure',

  acceptInvitationStart: '[user] accept invitation start',
  acceptInvitationSuccess: '[user] accept invitation success',
  acceptInvitationFailure: '[user] accept invitation failure',

  rejectInvitationStart: '[user] reject invitation start',
  rejectInvitationSuccess: '[user] reject invitation success',
  rejectInvitationFailure: '[user] reject invitation failure',

  getNotificationsStart: '[user] get notifications start',
  getNotificationsSuccess: '[user] get notifications success',
  getNotificationsFailure: '[user] get notifications failure',

  changeNotificationStart: '[user] change notification start',
  changeNotificationSuccess: '[user] change notification success',
  changeNotificationFailure: '[user] change notification failure',

  deleteNotificationStart: '[user] delete notification start',
  deleteNotificationSuccess: '[user] delete notification success',
  deleteNotificationFailure: '[user] delete notification failure',

  getSelfTargetsStart: '[user] get self targets start',
  getSelfTargetsSuccess: '[user] get self targets success',
  getSelfTargetsFailure: '[user] get self targets failure',

  getCuratorTargetsStart: '[user] get curator targets start',
  getCuratorTargetsSuccess: '[user] get curator targets success',
  getCuratorTargetsFailure: '[user] get curator targets failure',

  exportCuratorTargetsStart: '[user] export curator targets start',
  exportCuratorTargetsSuccess: '[user] export curator targets success',
  exportCuratorTargetsFailure: '[user] export curator targets failure',

  getCuratorAdaptationStart: '[user] get curator adaptation start',
  getCuratorAdaptationSuccess: '[user] get curator adaptation success',
  getCuratorAdaptationFailure: '[user] get curator adaptation failure',
};

const mutations = {
  [mutationTypes.getUsersStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getUsersSuccess](state, payload) {
    state.isLoading = false;
    state.users = payload;
  },
  [mutationTypes.getUsersFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false;
    state.currentUser = payload;
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getMetadataUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getMetadataUserSuccess](state, payload) {
    state.isLoading = false;
    state.metadata = payload;
  },
  [mutationTypes.getMetadataUserFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getPlatformRightsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getPlatformRightsSuccess](state, payload) {
    state.isLoading = false;
    state.platformRights = payload;
  },
  [mutationTypes.getPlatformRightsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCurrentAccessRightsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentAccessRightsSuccess](state, payload) {
    state.isLoading = false;
    state.currentAccessRights = payload;
  },
  [mutationTypes.getCurrentAccessRightsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getAccessRightsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getAccessRightsSuccess](state, payload) {
    state.isLoading = false;
    state.accessRights = payload;
  },
  [mutationTypes.getAccessRightsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getSpecsWithGradesStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSpecsWithGradesSuccess](state, payload) {
    state.isLoading = false;
    state.specsWithGrades = payload;
  },
  [mutationTypes.getSpecsWithGradesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeMetadataUserStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeMetadataUserSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeMetadataUserFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeAvatarStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeAvatarSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeAvatarFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeEmailStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeEmailSuccess](state) {
    state.isSubmitting = false;
    state.validationErrors = null;
    state.validationErrorCode = null;
  },
  [mutationTypes.changeEmailFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },
  [mutationTypes.changeEmailFailureCode](state, payload) {
    state.validationErrorCode = payload;
  },

  [mutationTypes.changeSpecializationsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeSpecializationsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeSpecializationsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeHRInfoStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeHRInfoSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeHRInfoFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createHRAttachmentStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createHRAttachmentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createHRAttachmentFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteHRAttachmentStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteHRAttachmentSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteHRAttachmentFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getEmployersStart](state) {
    state.isLoading = true;
    state.employers = null;
  },
  [mutationTypes.getEmployersSuccess](state, payload) {
    state.isLoading = false;
    state.employers = payload;
  },
  [mutationTypes.getEmployersFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCompaniesStart](state) {
    state.isLoading = true;
    state.companies = null;
  },
  [mutationTypes.getCompaniesSuccess](state, payload) {
    state.isLoading = false;
    state.companies = payload;
  },
  [mutationTypes.getCompaniesFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createCompanyStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createCompanySuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createCompanyFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.createCompanyLogoStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createCompanyLogoSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createCompanyLogoFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getPortfolioStart](state) {
    state.isLoading = true;
    state.portfolio = null;
  },
  [mutationTypes.getPortfolioSuccess](state, payload) {
    state.isLoading = false;
    state.portfolio = payload;
  },
  [mutationTypes.getPortfolioFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createPortfolioStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createPortfolioSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createPortfolioFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deletePortfolioStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deletePortfolioSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deletePortfolioFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getWorkExpStart](state) {
    state.isLoading = true;
    state.workExp = null;
  },
  [mutationTypes.getWorkExpSuccess](state, payload) {
    state.isLoading = false;
    state.workExp = payload;
  },
  [mutationTypes.getWorkExpFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.createWorkExpStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.createWorkExpSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.createWorkExpFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.changeWorkExpStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeWorkExpSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeWorkExpFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteWorkExpStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteWorkExpSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteWorkExpFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getEducationStart](state) {
    state.isLoading = true;
    state.education = null;
  },
  [mutationTypes.getEducationSuccess](state, payload) {
    state.isLoading = false;
    state.education = payload;
  },
  [mutationTypes.getEducationFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeEducationStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.changeEducationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeEducationFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.deleteEducationStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteEducationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteEducationFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getReportsStart](state) {
    state.isLoading = true;
    state.reports = null;
  },
  [mutationTypes.getReportsSuccess](state, payload) {
    state.isLoading = false;
    state.reports = payload;
  },
  [mutationTypes.getReportsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTests360Start](state) {
    state.isLoading = true;
    state.tests360 = null;
  },
  [mutationTypes.getTests360Success](state, payload) {
    state.isLoading = false;
    state.tests360 = payload;
  },
  [mutationTypes.getTests360Failure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getTests360ForApprovalStart](state) {
    state.isLoading = true;
    state.tests360ForApproval = null;
  },
  [mutationTypes.getTests360ForApprovalSuccess](state, payload) {
    state.isLoading = false;
    state.tests360ForApproval = payload;
  },
  [mutationTypes.getTests360ForApprovalFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.acceptInvitationStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.acceptInvitationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.acceptInvitationFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.rejectInvitationStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.rejectInvitationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.rejectInvitationFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getNotificationsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getNotificationsSuccess](state, payload) {
    state.isLoading = false;
    state.notifications = payload;
  },
  [mutationTypes.getNotificationsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.changeNotificationStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.changeNotificationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.changeNotificationFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.deleteNotificationStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.deleteNotificationSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.deleteNotificationFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getSelfTargetsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getSelfTargetsSuccess](state, payload) {
    state.isLoading = false;
    state.targets.self = payload;
  },
  [mutationTypes.getSelfTargetsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.getCuratorTargetsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCuratorTargetsSuccess](state, payload) {
    state.isLoading = false;
    state.targets.curator = payload;
  },
  [mutationTypes.getCuratorTargetsFailure](state) {
    state.isLoading = false;
  },

  [mutationTypes.exportCuratorTargetsStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.exportCuratorTargetsSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.exportCuratorTargetsFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getCuratorAdaptationStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCuratorAdaptationSuccess](state, payload) {
    state.isLoading = false;
    state.curator.adaptation = payload;
  },
  [mutationTypes.getCuratorAdaptationFailure](state) {
    state.isLoading = false;
  },
};

export default mutations;
