<i18n>
{
  "ru": {
  },
  "en": {
  }
}
</i18n>
<template>
  <div
    class="loading-container d-flex align-center justify-center"
    :class="[classes, type && `is-${type}`]"
  >
    <span class="loading d-flex">
      <svg viewBox="0 0 100 100">
        <circle class="loader-svg bg" cx="50" cy="50" r="45"></circle>
        <circle class="loader-svg animate" cx="50" cy="50" r="45"></circle>
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppLoading',
  props: {
    type: {
      required: false,
      type: String,
      default: null,
    },
    classes: {
      required: false,
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/colors';

.loading-container {
  position: absolute;
  z-index: get($index, 'xxl');
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.is-inline {
    position: relative;
    width: $decimal-spacer * 3;
    height: $decimal-spacer * 3;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes fill-animation {
    0% {
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 141.3;
      stroke-dashoffset: 141.3;
    }
    100% {
      stroke-dasharray: 40 242.6;
      stroke-dashoffset: 282.6;
    }
  }
  .loading {
    /*animation: spin 1s linear infinite;*/
    svg {
      fill: none;
      width: $decimal-spacer * 3;
      height: $decimal-spacer * 3;

      circle {
        stroke-width: $border-width * 5;
        stroke-linecap: round;
        stroke: get($grey, 'base');

        &.animate {
          stroke-dasharray: 242.6;
          animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
        }
        &.bg {
          stroke-width: $border-width * 8;
          stroke: get($grey, 'lighten-2');
        }
      }
    }
  }
}
.is-loading {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
</style>
