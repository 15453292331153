import interconnectivityApi from '@/api/interconnectivity';
import {mutationTypes} from '@/store/modules/interconnectivity/mutations';

export const actionsTypes = {
  getAvailability: '[interconnectivity] get availability',
};

const actions = {
  [actionsTypes.getAvailability](context, {companyId, params}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAvailabilityStart);
      interconnectivityApi
        .getAvailability(companyId, params)
        .then((response) => {
          context.commit(mutationTypes.getAvailabilitySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAvailabilityFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
