export default {
  companies: null,
  metadata: null,
  allCompanyList: [],
  currentSpecializations: null,
  specializations: null,
  currentSettings: null,
  settings: null,
  statusesImportEmployees: null,
  recordTypes: null,

  portfolio: null,
  managers: null,
  employees: null,
  links: null,
  roadmaps: null,
  subordinateEmployees: null,

  metadataTests360: null,
  metadataPersonalTests: null,

  teams: null,
  allTeams: null,
  managedTeams: null,
  teamsTree: null,
  teamGroupsFlat: null,
  teamGroups: null,
  teamGroup: null,

  tests360: [],
  personal: [],
  license: [],
  adaptation: [],

  targets: null,
  allTargets: null,
  curators: null,

  isLoading: false,
  companyListLoading: false,
  checkTeamTests360Loading: false,

  isSubmitting: false,
  errors: null,
  validationErrors: null,
};
